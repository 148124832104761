import React, { useState } from 'react';
import SummaryScoreGraph from './SummaryScoreGraph';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, ChevronUp } from 'lucide-react';
import './ScoreGraph.css';

// Import the subChapters configuration
const subChapters = [
  {
    title: 'Quality',
    points: [
      {
        name: 'Readability',
        scoreAttribute: 'readibility_score'
      },
      {
        name: 'Consistency',
        scoreAttribute: 'consistency_score'
      },
      {
        name: 'Modularity and Maintainability',
        scoreAttribute: 'modularity_score'
      },
      {
        name: 'Reusability',
        scoreAttribute: 'reusability_score'
      },
      {
        name: 'Redundancy and Technical Debt',
        scoreAttribute: 'redundancy_score'
      },
      {
        name: 'Code Smells',
        scoreAttribute: 'code_smells_score'
      }
    ]
  },
  {
    title: 'Functionality',
    points: [
      {
        name: 'Completeness',
        scoreAttribute: 'completeness_score'
      },
      {
        name: 'Edge Cases',
        scoreAttribute: 'edge_cases_score'
      },
      {
        name: 'Error Handling',
        scoreAttribute: 'error_handling_score'
      }
    ]
  },
  {
    title: 'Scalability',
    points: [
      {
        name: 'Efficiency',
        scoreAttribute: 'efficiency_score'
      },
      {
        name: 'Scalability',
        scoreAttribute: 'scalability_score'
      },
      {
        name: 'Resource Utilization',
        scoreAttribute: 'resource_utilization_score'
      },
      {
        name: 'Load Handling',
        scoreAttribute: 'load_handling_score'
      },
      {
        name: 'Parallel Processing',
        scoreAttribute: 'parallel_processing_score'
      },
      {
        name: 'Database Interaction Efficiency',
        scoreAttribute: 'database_interaction_efficiency_score'
      },
      {
        name: 'Concurrency Management',
        scoreAttribute: 'concurrency_management_score'
      },
      {
        name: 'State Management Efficiency',
        scoreAttribute: 'state_management_efficiency_score'
      },
      {
        name: 'Modularity and Decoupling',
        scoreAttribute: 'modularity_decoupling_score'
      },
      {
        name: 'Configuration and Customization Ease',
        scoreAttribute: 'configuration_customization_ease_score'
      }
    ]
  },
  {
    title: 'Security',
    points: [
      {
        name: 'Input Validation',
        scoreAttribute: 'input_validation_score'
      },
      {
        name: 'Data Handling',
        scoreAttribute: 'data_handling_score'
      },
      {
        name: 'Authentication',
        scoreAttribute: 'authentication_score'
      }
    ]
  },
  {
    title: 'Compatibility',
    points: [
      {
        name: 'Independence and Flexibility',
        scoreAttribute: 'independence_score'
      },
      {
        name: 'Integration Capabilities',
        scoreAttribute: 'integration_score'
      }
    ]
  },
  {
    title: 'Documentation',
    points: [
      {
        name: 'Inline Comments',
        scoreAttribute: 'inline_comments_score'
      }
    ]
  },
  {
    title: 'Standards',
    points: [
      {
        name: 'Standards Compliance',
        scoreAttribute: 'standards_score'
      },
      {
        name: 'Design Patterns',
        scoreAttribute: 'design_patterns_score'
      },
      {
        name: 'Code Complexity',
        scoreAttribute: 'code_complexity_score'
      },
      {
        name: 'Refactoring Opportunities',
        scoreAttribute: 'refactoring_opportunities_score'
      }
    ]
  }
];

const ANIMATION_DELAY = 150;
const SUB_ANIMATION_DELAY = 100;

const ScoreGraph = ({ scoresData, auditDetails }) => {
  const [expandedSections, setExpandedSections] = useState({});

  const getSubpoints = (title) => {
    const chapterData = subChapters.find(chapter => chapter.title === title);
    if (!chapterData) return [];
    
    return chapterData.points
      .filter(point => point.scoreAttribute)
      .map(point => ({
        title: point.name,
        scoreAttribute: point.scoreAttribute
      }));
  };

  const toggleSection = (title) => {
    setExpandedSections(prev => ({
      ...prev,
      [title]: !prev[title]
    }));
  };

  return (
    <div className="score-graph-container">
      {scoresData.map((data, index) => (
        <div key={data.scoreAttribute} className="chapter-section">
          {/* Main chapter row */}
          <div 
            className="score-row main-row"
            onClick={() => toggleSection(data.title)}
            style={{
              '--row-delay': `${index * ANIMATION_DELAY}ms`,
              cursor: 'pointer'
            }}
          >
            <div className="score-title main-title">
              {expandedSections[data.title] ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
              <span>{data.title}</span>
            </div>
            <div className="score-slider-graph">
              <SummaryScoreGraph
                scoreAttribute={data.scoreAttribute}
                name={data.title}
                isMainChapter={true}
                auditDetails={auditDetails}
                animationDelay={index * ANIMATION_DELAY}
              />
            </div>
          </div>

          {/* Subpoints */}
          <AnimatePresence>
            {expandedSections[data.title] && (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: 'auto', opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ duration: 0.3 }}
                className="subpoints-container"
              >
                {getSubpoints(data.title).map((subpoint, subIndex) => (
                  <motion.div
                    key={subpoint.scoreAttribute}
                    className="score-row sub-row"
                    initial={{ x: -20, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ 
                      delay: subIndex * 0.1,
                      duration: 0.3
                    }}
                    style={{
                      '--row-delay': `${(index * ANIMATION_DELAY) + ((subIndex + 1) * SUB_ANIMATION_DELAY)}ms`
                    }}
                  >
                    <div className="score-title sub-title">{subpoint.title}</div>
                    <div className="score-slider-graph">
                      <SummaryScoreGraph
                        scoreAttribute={subpoint.scoreAttribute}
                        name={subpoint.title}
                        isMainChapter={false}
                        auditDetails={auditDetails}
                        animationDelay={(index * ANIMATION_DELAY) + ((subIndex + 1) * SUB_ANIMATION_DELAY)}
                      />
                    </div>
                  </motion.div>
                ))}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      ))}
    </div>
  );
};

export default ScoreGraph;