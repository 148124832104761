import pythonLogo from '../../../assets/images/languages/python-logo.png';
import jsLogo from '../../../assets/images/languages/js-logo.png';
import cppLogo from '../../../assets/images/languages/cpp-logo.png';
import javaLogo from '../../../assets/images/languages/java-logo.png';

export const codeExamples = {
    python: {
        language: 'Python',
        icon: pythonLogo,
        description: 'A versatile language known for its readability and extensive libraries.',
        code: `def fibonacci(n):
    """Generate Fibonacci sequence up to n"""
    sequence = [0, 1]
    while sequence[-1] <= n:
        sequence.append(sequence[-1] + sequence[-2])
    return sequence[:-1]

def prime_factors(n):
    """Find prime factors of a number"""
    factors = []
    d = 2
    while n > 1:
        while n % d == 0:
            factors.append(d)
            n //= d
        d += 1
        if d * d > n:
            if n > 1:
                factors.append(n)
            break
    return factors

def main():
    num = 100
    print(f"Fibonacci sequence up to {num}:", fibonacci(num))
    print(f"Prime factors of {num}:", prime_factors(num))
    
if __name__ == "__main__":
    main()`
    },
    javascript: {
        language: 'JavaScript',
        icon: jsLogo,
        description: 'A dynamic language for web development and beyond.',
        code: `class DataStructures {
    constructor() {
        this.stack = [];
        this.queue = [];
        this.map = new Map();
    }

    // Stack operations
    push(item) {
        this.stack.push(item);
        return this.stack.length;
    }

    pop() {
        return this.stack.pop();
    }

    // Queue operations
    enqueue(item) {
        this.queue.push(item);
        return this.queue.length;
    }

    dequeue() {
        return this.queue.shift();
    }

    // Map operations with error handling
    setItem(key, value) {
        try {
            this.map.set(key, value);
            return true;
        } catch (error) {
            console.error('Error setting item:', error);
            return false;
        }
    }

    getItem(key) {
        try {
            if (!this.map.has(key)) {
                throw new Error('Key not found');
            }
            return this.map.get(key);
        } catch (error) {
            console.error('Error getting item:', error);
            return null;
        }
    }
}

// Usage example
const ds = new DataStructures();
ds.push('first');
ds.enqueue('second');
ds.setItem('key', 'value');

console.log(ds.pop());
console.log(ds.dequeue());
console.log(ds.getItem('key'));`
    },
    cpp: {
        language: 'cpp',
        icon: cppLogo,
        description: 'A powerful systems programming language.',
        code: `#include <iostream>
#include <vector>
#include <memory>
#include <algorithm>

template<typename T>
class BinarySearchTree {
private:
    struct Node {
        T data;
        std::unique_ptr<Node> left;
        std::unique_ptr<Node> right;
        Node(T value) : data(std::move(value)) {}
    };
    
    std::unique_ptr<Node> root;

    void insert_recursive(std::unique_ptr<Node>& node, T value) {
        if (!node) {
            node = std::make_unique<Node>(std::move(value));
            return;
        }
        if (value < node->data) {
            insert_recursive(node->left, value);
        } else {
            insert_recursive(node->right, value);
        }
    }

    void inorder_recursive(const Node* node, std::vector<T>& result) const {
        if (!node) return;
        inorder_recursive(node->left.get(), result);
        result.push_back(node->data);
        inorder_recursive(node->right.get(), result);
    }

public:
    void insert(T value) {
        insert_recursive(root, std::move(value));
    }

    std::vector<T> inorder_traversal() const {
        std::vector<T> result;
        inorder_recursive(root.get(), result);
        return result;
    }
};

int main() {
    BinarySearchTree<int> bst;
    std::vector<int> values = {5, 3, 7, 1, 9, 4, 6};
    
    for (const auto& value : values) {
        bst.insert(value);
    }

    auto sorted = bst.inorder_traversal();
    std::cout << "Sorted values: ";
    for (const auto& value : sorted) {
        std::cout << value << " ";
    }
    std::cout << std::endl;

    return 0;
}`
    },
    java: {
        language: 'Java',
        icon: javaLogo,
        description: 'A class-based, object-oriented programming language.',
        code: `import java.util.concurrent.*;
import java.util.stream.*;
import java.util.*;

public class ConcurrentProcessing {
    private final ExecutorService executor;
    private final ConcurrentHashMap<String, Integer> results;
    
    public ConcurrentProcessing(int threads) {
        this.executor = Executors.newFixedThreadPool(threads);
        this.results = new ConcurrentHashMap<>();
    }
    
    public CompletableFuture<Integer> processData(List<String> data) {
        return CompletableFuture.supplyAsync(() -> {
            return data.parallelStream()
                .map(this::processItem)
                .reduce(0, Integer::sum);
        }, executor);
    }
    
    private int processItem(String item) {
        try {
            // Simulate complex processing
            Thread.sleep(100);
            return results.computeIfAbsent(item, 
                k -> k.chars().map(c -> c - 'a' + 1).sum());
        } catch (InterruptedException e) {
            Thread.currentThread().interrupt();
            return 0;
        }
    }
    
    public void shutdown() {
        executor.shutdown();
        try {
            if (!executor.awaitTermination(1, TimeUnit.SECONDS)) {
                executor.shutdownNow();
            }
        } catch (InterruptedException e) {
            executor.shutdownNow();
            Thread.currentThread().interrupt();
        }
    }
    
    public static void main(String[] args) {
        ConcurrentProcessing processor = 
            new ConcurrentProcessing(Runtime.getRuntime().availableProcessors());
            
        List<String> testData = Arrays.asList(
            "concurrent", "processing", "example", "java");
            
        try {
            int result = processor.processData(testData)
                .get(5, TimeUnit.SECONDS);
            System.out.println("Result: " + result);
        } catch (Exception e) {
            e.printStackTrace();
        } finally {
            processor.shutdown();
        }
    }
}`
    }
}; 