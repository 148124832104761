// RouteConfig.js
import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectAuth } from './features/user/userSlice';
import { checkAuditStatus } from './utils/Actions';

export const PrivateRoute = () => {
    const [isChecking, setIsChecking] = useState(true);
    const { isLoggedIn } = useSelector(selectAuth);
    const location = useLocation();
    const dispatch = useDispatch();

    // Get audit UUID from path if it exists
    const getAuditUuidFromPath = () => {
        const matches = location.pathname.match(/\/([^\/]+)\/(audit[_-].*|dependencies|flags|files)/);
        if (matches) {
            return matches[1];
        }

        const simpleMatch = location.pathname.split('/')[1];
        const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
        if (simpleMatch && uuidPattern.test(simpleMatch)) {
            return simpleMatch;
        }

        return null;
    };

    const auditUuid = getAuditUuidFromPath();
    const auditAccessDetails = useSelector(state => state.audits?.[auditUuid]);

    useEffect(() => {
        const checkAccess = async () => {
            if (isLoggedIn && auditUuid) {
                try {
                    await dispatch(checkAuditStatus(auditUuid));
                } catch (error) {
                    console.error('Failed to check audit access:', error);
                }
            }
            setIsChecking(false);
        };

        checkAccess();
    }, [isLoggedIn, auditUuid, dispatch]);

    // Show loading state while checking
    if (isChecking && auditUuid) {
        return <div className="loader-container"><div className="loader"></div></div>;
    }

    // If not logged in, redirect to login
    if (!isLoggedIn) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    // If this is an audit route but we don't have access details, redirect to dashboard
    if (auditUuid && !auditAccessDetails) {
        return <Navigate to="/dashboard" replace />;
    }

    // If we have access details but don't have access, redirect to dashboard
    if (auditAccessDetails && !auditAccessDetails.hasAccess && !auditAccessDetails.isPublic) {
        return <Navigate to="/dashboard" replace />;
    }

    // Allow access to the route
    return <Outlet />;
};