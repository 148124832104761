export const scoreDescriptions = {
  // Quality
  readibility: "How easy the code is to read and understand, including naming conventions, formatting, and structure",
  consistency: "Uniform coding style, patterns, and conventions throughout the codebase",
  modularity: "How well the code is organized into independent, reusable components",
  reusability: "The extent to which code components can be reused in different contexts",
  redundancy: "Unnecessary duplication of code or logic",
  code_smells: "Indicators of potential problems in code design or implementation",
  
  // Functionality
  completeness: "How fully the code implements all required features and functionality",
  edge_cases: "Handling of exceptional or boundary conditions in the code",
  error_handling: "How effectively the code handles and manages errors and exceptions",
  
  // Scalability
  efficiency: "Resource usage and execution speed optimization",
  scalability: "Ability to handle growing amounts of work effectively",
  resource_utilization: "How effectively system resources are used",
  load_handling: "Capability to manage varying workloads",
  parallel_processing: "Ability to execute multiple operations simultaneously",
  database_interaction_efficiency: "Optimization of database queries and operations",
  concurrency_management: "Handling of simultaneous operations and shared resources",
  state_management_efficiency: "Effectiveness of application state handling",
  modularity_decoupling: "Independence between different parts of the system",
  configuration_customization_ease: "Flexibility in system configuration and customization",
  
  // Security
  input_validation: "Verification and sanitization of input data",
  data_handling: "Security of data processing and storage",
  authentication: "User identity verification and access control",
  
  // Compatibility
  independence: "Freedom from platform-specific dependencies",
  integration: "Ease of integration with other systems",
  
  // Documentation
  inline_comments: "Quality and completeness of code documentation",
  
  // Standards
  standards: "Adherence to coding standards and best practices",
  design_patterns: "Use of established software design patterns",
  code_complexity: "Measure of code structural complexity",
  refactoring_opportunities: "Potential for code improvement through restructuring"
}; 