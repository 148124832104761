import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { Editor } from 'primereact/editor';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import AuditScopeSummaryTabMenu from './AuditScopeSummaryTabMenu';
import { useCsrfToken } from '../utils/CsrfTokenContext';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import './AuditScopeSelection.css';
import Loader from '../assets/spinner/Loader';

function AuditScopeSelection() {
    const { csrfToken } = useCsrfToken();
    const [nodes, setNodes] = useState([]);
    const [selectedNodeKeys, setSelectedNodeKeys] = useState({});
    const [selectedNodes, setSelectedNodes] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [originalData, setOriginalData] = useState([]);
    const [auditStatus, setAuditStatus] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [filterMode, setFilterMode] = useState('contains');
    const [selectedTypes, setSelectedTypes] = useState([]);
    const [typeOptions, setTypeOptions] = useState([]);
    const [expandedRows, setExpandedRows] = useState({});
    const [showSidebar, setShowSidebar] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const [editorContent, setEditorContent] = useState('');
    const toast = useRef(null);

    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const getStoredState = () => {
        try {
            return JSON.parse(sessionStorage.getItem('navigationState'));
        } catch {
            return null;
        }
    };

    const auditUuid = new URLSearchParams(location.search).get('auditUuid') || params.auditUuid;
    const navigationState = location.state || getStoredState() || {};
    const groupAuditUuid = navigationState.groupAuditUuid;
    const accountUuid = navigationState.accountUuid;

    useEffect(() => {
        console.log('AuditScopeSelection - Full Location:', location);
        console.log('AuditScopeSelection - Navigation State:', navigationState);
        console.log('AuditScopeSelection - Audit UUID:', auditUuid);
        console.log('AuditScopeSelection - Group Audit UUID:', groupAuditUuid);
        console.log('AuditScopeSelection - Account UUID:', accountUuid);
    }, [location, navigationState, auditUuid, groupAuditUuid, accountUuid]);

    useEffect(() => {
        const fetchAuditScope = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/django_codedd/audit_scope_selector/${auditUuid}/`,
                    {
                        params: {
                            account_uuid: accountUuid
                        }
                    }
                );
                if (response.data && response.data.tree_structure) {
                    const data = response.data.tree_structure;
                    const status = response.data.audit_status;
                    setAuditStatus(status);
;
                    const formattedData = formatAndSortData(data);
                    setOriginalData(formattedData);

                    // Initialize selectedNodeKeys based on is_selected property
                    const initialSelectedNodeKeys = initializeSelectedNodeKeys(formattedData); 
                    setSelectedNodeKeys(initialSelectedNodeKeys);

                    // Extract unique types for the MultiSelect options
                    const uniqueTypes = Array.from(new Set(data.map(node => node.data.type)));
                    setTypeOptions(uniqueTypes.map(type => ({ label: type, value: type })));
                } else {
                    console.error('No tree structure data found in response');
                }
            } catch (error) {
                console.error('Error fetching audit scope:', error);
                if (error.response?.status === 403) {
                    // Clear stored state on error
                    sessionStorage.removeItem('navigationState');
                    navigate('/dashboard');
                }
            } finally {
            setIsLoading(false);
            }
        };

        if (auditUuid) {
            fetchAuditScope();
        }
    }, [auditUuid, location, accountUuid]);

    useEffect(() => {
        // Fetch existing comments for the audit
        axios.get(`${process.env.REACT_APP_API_URL}/django_codedd/api/get_file_selection_comments/${auditUuid}/`)
            .then(response => {
                if (response.data && response.data.comments.length > 0) {
                    setEditorContent(response.data.comments[0]);
                } else {
                    setEditorContent('');
                }
            })
            .catch(error => {
                console.error('Error fetching file comments:', error);
            });
    }, [auditUuid]);

    // Function to handle the submission of editor content
    const handleSubmitComment = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/django_codedd/api/post_file_selection_comments/`, {
            audit_uuid: auditUuid,
            comment: editorContent
        },
        {
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': csrfToken,
            },
            withCredentials: true
          })
        .then(response => {
            console.log('Comment submitted:', response.data);
            showSuccess();
        })
        .catch(error => {
            console.error('Error submitting comment:', error);
            showError();
        });
    };

    // Function to display success toast
    const showSuccess = () => {
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Comment submitted successfully', life: 3000 });
    };

    // Function to display error toast
    const showError = () => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error submitting comment', life: 3000 });
    };

    // Initialize selectedNodeKeys based on is_selected property from data
    const initializeSelectedNodeKeys = (nodes) => {
        let newSelectedNodeKeys = {};
        const processNode = (node) => {
            if (isSelectable(node) && node.data.is_selected) {
                newSelectedNodeKeys[node.key] = { checked: true, partialChecked: false };
            }
            if (node.children) {
                node.children.forEach(processNode);
                updateParentSelection(node, newSelectedNodeKeys);
            }
        };
        nodes.forEach(processNode);
        return newSelectedNodeKeys;
    };

    const getAllSelectableDescendants = (node) => {
        let selectableNodes = [];
        
        // If the current node is selectable, add it
        if (isSelectable(node)) {
            selectableNodes.push(node);
        }
        
        // Recursively get selectable nodes from children
        if (node.children) {
            node.children.forEach(child => {
                selectableNodes = [...selectableNodes, ...getAllSelectableDescendants(child)];
            });
        }
        
        return selectableNodes;
    };
    
    const checkNodeSelectionState = (node, selectedKeys) => {
        // If node isn't selectable, return zero counts
        if (!isSelectable(node)) {
            return { selected: 0, partial: 0, total: 0 };
        }
    
        // For files, return direct state
        if (!node.children) {
            return {
                selected: selectedKeys[node.key]?.checked ? 1 : 0,
                partial: 0,
                total: 1
            };
        }
    
        // For folders, aggregate selectable descendants
        let totalSelected = 0;
        let totalPartial = 0;
        let totalSelectable = 0;
    
        node.children.forEach(child => {
            if (isSelectable(child)) {
                const childState = checkNodeSelectionState(child, selectedKeys);
                totalSelected += childState.selected;
                totalPartial += childState.partial;
                totalSelectable += childState.total;
            }
        });
    
        return {
            selected: totalSelected,
            partial: totalPartial,
            total: totalSelectable
        };
    };
    
    const updateParentSelection = (node, selectedKeys) => {
        if (!node.parent) return;
    
        const parent = node.parent;
        const state = checkNodeSelectionState(parent, selectedKeys);
    
        console.log(`Updating parent ${parent.data.name}:`, state);
    
        // Clear any existing state first
        delete selectedKeys[parent.key];
    
        // Update parent state based on all descendants
        if (state.total > 0) { // Only update if there are selectable descendants
            if (state.selected === state.total) {
                // All descendants selected = parent fully selected
                selectedKeys[parent.key] = { checked: true, partialChecked: false };
            } else if (state.selected > 0 || state.partial > 0) {
                // Some descendants selected = parent partially selected
                selectedKeys[parent.key] = { checked: false, partialChecked: true };
            }
            // If nothing is selected, the deletion above handles it
        }
    
        // Recursively update ancestors
        if (parent.parent) {
            updateParentSelection(parent, selectedKeys);
        }
    
        // Recursively update parent folders
        updateParentSelection(parent, selectedKeys);
    };

    const propagateSelection = (node, isSelected, selectedKeys) => {
        if (isSelectable(node)) {
            if (isSelected) {
                selectedKeys[node.key] = { checked: true, partialChecked: false };
            } else {
                delete selectedKeys[node.key];
            }
        }
    
        // Propagate to children
        if (node.children) {
            node.children.forEach(child => {
                propagateSelection(child, isSelected, selectedKeys);
            });
        }
    };

    // Function to gather selected nodes
    const gatherSelectedNodes = (nodes, selectedKeys) => {
        let selected = [];
        nodes.forEach(node => {
            if (selectedKeys[node.key]?.checked && 
                node.data.type !== 'Folder' && 
                isSelectable(node)) {
                selected.push({
                    ...node,
                    data: {
                        ...node.data,
                        lines_of_code: node.data.size  // Ensure lines_of_code is set correctly
                    }
                });
            }
            if (node.children) {
                selected = [...selected, ...gatherSelectedNodes(node.children, selectedKeys)];
            }
        });
        return selected;
    };

    const isSelectable = (node) => {
        // Don't allow selection of excluded files
        if (node.data.type === 'Excluded') return false;
        
        // For folders, check if they have any selectable descendants
        if (node.data.type === 'Folder') {
            return hasSelectableDescendants(node);
        }
        
        // For files, check if they have content and are of allowed type
        return node.data.size > 0 && (
            node.data.type === 'Source Code' || 
            (node.data.type === 'Other' && node.data.name !== 'README.md')
        );
    };

    // Add helper function to check for selectable descendants
    const hasSelectableDescendants = (node) => {
        if (!node.children) return false;
        
        return node.children.some(child => {
            if (isSelectable(child) && child.data.type !== 'Folder') return true;
            return hasSelectableDescendants(child);
        });
    };

    // Function to format and sort data to fit the TreeTable structure
    const formatAndSortData = (data) => {
        const processNode = (node, parent = null) => {
            const formattedNode = {
                key: node.key,
                parent: parent,
                data: {
                    ...node.data,
                    name: node.data.name,
                    size: node.data.size,
                    type: node.data.type,
                    fullPath: node.data.fullPath,
                    is_selected: node.data.is_selected,
                    lines_of_code: node.data.size
                }
            };
    
            if (node.children && node.children.length > 0) {
                formattedNode.children = node.children
                    .map(child => processNode(child, formattedNode))
                    .sort((a, b) => {
                        if (a.data.type === 'Folder' && b.data.type !== 'Folder') return -1;
                        if (a.data.type !== 'Folder' && b.data.type === 'Folder') return 1;
                        return a.data.name.localeCompare(b.data.name);
                    });
            }
    
            return formattedNode;
        };
    
        return data
            .map(node => processNode(node))
            .sort((a, b) => {
                if (a.data.type === 'Folder' && b.data.type !== 'Folder') return -1;
                if (a.data.type !== 'Folder' && b.data.type === 'Folder') return 1;
                return a.data.name.localeCompare(b.data.name);
            });
    };

    // Filter nodes by type when selectedTypes changes
    useEffect(() => {
        const filterByType = (nodeList) => {
            return nodeList.map(node => {
                if (selectedTypes.length === 0 || selectedTypes.includes(node.data.type)) {
                    return {
                        ...node,
                        children: node.children ? filterByType(node.children) : []
                    };
                }
                return null;
            }).filter(node => node);
        };

        // Re-apply the filter every time selectedTypes change
        if (selectedTypes.length > 0) {
            // Apply the filter to a copy of the original data
            const filteredNodes = filterByType([...originalData]);
            setNodes(filteredNodes);
        } else {
            // Reset to original data when no types are selected
            setNodes(originalData);
        }
    }, [selectedTypes, originalData]);

    // Update selectedNodes based on the selectedNodeKeys state
    useEffect(() => {
        const selectedNodesData = gatherSelectedNodes(nodes, selectedNodeKeys);
        setSelectedNodes(selectedNodesData);
    }, [selectedNodeKeys, nodes]);

    const renderLinesOfCode = (node) => {
        // Only show size for non-folder nodes or folders with size > 0
        if (node.data.type !== 'Folder' || node.data.size > 0) {
            return node.data.size.toLocaleString('de-DE');
        }
        return null;
    };

    // Update the onSelectionChange function to handle partial selection for folders
    const onSelectionChange = (node, checked) => {
        if (auditStatus !== "File information imported") return;
        
        const newKeys = { ...selectedNodeKeys };
        
        // Clear existing state for this node
        delete newKeys[node.key];
        
        if (checked && isSelectable(node)) {
            newKeys[node.key] = { checked: true, partialChecked: false };
        }
    
        // Update descendants
        if (node.children) {
            const updateDescendants = (currentNode) => {
                // Clear existing state
                delete newKeys[currentNode.key];
                
                if (isSelectable(currentNode)) {
                    if (checked) {
                        newKeys[currentNode.key] = { checked: true, partialChecked: false };
                    }
                }
                if (currentNode.children) {
                    currentNode.children.forEach(updateDescendants);
                }
            };
            node.children.forEach(updateDescendants);
        }
    
        // Update all ancestors
        let currentNode = node;
        while (currentNode.parent) {
            updateParentSelection(currentNode, newKeys);
            currentNode = currentNode.parent;
        }
        
        console.log('Final selection state:', newKeys);
        setSelectedNodeKeys(newKeys);
    };

    const CustomCheckbox = ({ node, auditStatus, selectedNodeKeys, onSelectionChange }) => {
        const selectionState = selectedNodeKeys[node.key] || {};
        const isChecked = selectionState.checked || false;
        const isPartialChecked = selectionState.partialChecked || false;
        
        return (
            <div className={`custom-checkbox ${auditStatus !== "File information imported" ? "disabled" : ""}`}
                 onClick={() => auditStatus === "File information imported" && onSelectionChange(node, !isChecked)}>
                <div className={`checkbox-box ${isChecked ? 'checked' : ''} ${isPartialChecked ? 'partial' : ''}`}>
                    {isChecked && !isPartialChecked && (
                        <svg viewBox="0 0 14 14" className="check-icon">
                            <path d="M11.5,3.5 l-5,5 l-2.5-2" 
                                  stroke="white" 
                                  strokeWidth="2" 
                                  fill="none" />
                        </svg>
                    )}
                    {isPartialChecked && (
                        <svg width="14" height="14" viewBox="0 0 14 14">
                            <path d="M2 7h10" 
                                  stroke="white" 
                                  strokeWidth="2"
                                  strokeLinecap="round" />
                        </svg>
                    )}
                </div>
            </div>
        );
    };

    // Selection template function now returns the component
    const selectionTemplate = (node) => {
        if (!isSelectable(node) || auditStatus !== "File information imported") {
            return null;
        }

        if (!isSelectable(node) || node.data.size === 0) {
            return null;
        }

        return (
            <CustomCheckbox
                node={node}
                auditStatus={auditStatus}
                selectedNodeKeys={selectedNodeKeys}
                onSelectionChange={onSelectionChange}
            />
        );
    };

    const nodeTemplate = (node) => {
        return (
            <span 
                className={isSelectable(node) ? '' : 'non-selectable-node'}
                data-p-type={node.data.type}
                title={node.data.name}
            >
                {node.data.name}
            </span>
        );
    };

    // Function to get all node keys
    const getAllKeys = (nodes) => {
        let keys = {};
        nodes.forEach(node => {
            if (isSelectable(node)) {
                keys[node.key] = { checked: true, partialChecked: false };
                if (node.children) {
                    Object.assign(keys, getAllKeys(node.children));
                }
            }
        });
        return keys;
    };

    // Handle select/deselect all functionality
    const onSelectAllChange = (e) => {

    if (auditStatus !== "File information imported") return;
    
    if (e.checked) {
        const newKeys = getAllKeys(nodes);
        // Update parent selections
        nodes.forEach(node => {
            if (node.parent) {
                updateParentSelection(node.parent, newKeys);
            }
        });
        setSelectedNodeKeys(newKeys);
        setSelectAll(true);
    } else {
        setSelectedNodeKeys({});
        setSelectAll(false);
    }
};

    // Automatically update selectAll state based on individual selections
    useEffect(() => {
        const selectableNodes = nodes.reduce((count, node) => {
            const countSelectable = (n) => {
                let c = isSelectable(n) && n.data.type !== 'Folder' ? 1 : 0;
                if (n.children) {
                    n.children.forEach(child => {
                        c += countSelectable(child);
                    });
                }
                return c;
            };
            return count + countSelectable(node);
        }, 0);
    
        const selectedCount = Object.values(selectedNodeKeys)
            .filter(val => val.checked)
            .length;
    
        setSelectAll(selectedCount > 0 && selectedCount >= selectableNodes);
    }, [selectedNodeKeys, nodes]);

    const onNodeToggle = (e) => {
        setExpandedRows(e.value);
    };

    const tableheader = (
        <div className="table-header">
            <div className="select-container">
                <Checkbox onChange={onSelectAllChange} checked={selectAll} />
                <label>{selectAll ? 'Deselect All' : 'Select All'}</label>
            </div>
            <span className="p-input-icon-right">
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
            </span>
        </div>
    );

    const renderEditorHeader = () => {
        return (
            <span className="ql-formats">
                <button className="ql-bold" title="Bold" aria-label="Bold"></button>
                <button className="ql-italic" title="Italic" aria-label="Italic"></button>
                <button className="ql-underline" title="Underline" aria-label="Underline"></button>
                <button className="ql-strike" title="Strike" aria-label="Strike"></button>
                <button className="ql-header" value="1" title="Header 1" aria-label="Header 1"></button>
                <button className="ql-header" value="2" title="Header 2" aria-label="Header 2"></button>
                <button className="ql-list" value="bullet" title="Bullet List" aria-label="Bullet List"></button>
                <button className="ql-clean" title="Remove Formatting" aria-label="Clean"></button>
            </span>
        );
    };

    const editorHeader = renderEditorHeader();

    return (
        <div className={`audit-scope-selection ${auditStatus !== "File information imported" ? 'disabled' : ''}`}>
            {isLoading && <Loader />}
            <div className="header-text">
                <h2>Select files you want to include in your due diligence.</h2>
                <p className="sub-text">
                    CodeDD automatically will only select source-code files to process.
                </p>
                <p className="sub-text">
                    If you want to include or exclude other files, please select them manually.
                </p>
            </div>

            <AuditScopeSummaryTabMenu
                visible={showSidebar}
                onHide={() => setShowSidebar(false)}
                selectedNodes={selectedNodes}
                groupAuditUuid={groupAuditUuid}
                auditStatus={auditStatus}
            />
            <TreeTable
                value={nodes}
                globalFilter={globalFilter}
                header={tableheader}
                filterMode={filterMode}
                selectionKeys={auditStatus === "File information imported" ? selectedNodeKeys : {}}
                onSelectionChange={auditStatus === "File information imported" ? onSelectionChange : null}
                expandedRows={expandedRows}
                onNodeToggle={onNodeToggle}
                tableStyle={{ minWidth: '50rem' }}
                rowClassName={(node) => `${node.data.type} ${auditStatus !== "File information imported" ? 'disabled' : ''}`}
            >
                <Column
                    selectionMode="multiple"
                    headerStyle={{ width: '3rem' }}
                    body={selectionTemplate}
                />
                <Column
                    field="name"
                    header="Name"
                    expander
                    filter
                    filterPlaceholder="Filter by name"
                    className="name-column"
                    body={nodeTemplate}
                />
                <Column
                    field="size"
                    header="Lines of Code"
                    body={renderLinesOfCode}
                />
                <Column field="type" header="Type" />
            </TreeTable>
            <div className="comments-container">
                <h3>You can comment on your file selection here:</h3>
                <Editor
                    style={{ height: '250px' }}
                    headerTemplate={editorHeader}
                    value={editorContent}
                    onTextChange={(e) => setEditorContent(e.htmlValue)}
                    disabled={auditStatus !== "File information imported"}
                />
                <Button
                    className="save_button"
                    label="Save"
                    icon="pi pi-check"
                    onClick={handleSubmitComment}
                    style={{ marginTop: '1rem'}}
                    disabled={auditStatus !== "File information imported"}
                />
                <Toast ref={toast} />
            </div>
        </div>
    );
}

export default AuditScopeSelection;
