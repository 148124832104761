import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';

const UserStatisticsGraph = ({ data, timePeriod, aggregation }) => {
    const d3Container = useRef(null);
    const [dimensions, setDimensions] = useState({ width: 600, height: 400 });

    useEffect(() => {
        function handleResize() {
            if (d3Container.current) {
                const { width } = d3Container.current.getBoundingClientRect();
                setDimensions({ width: width, height: Math.min(400, width * 0.75) });
            }
        }

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const getIntelligentTicks = (domain, width, timePeriod, aggregation) => {
        const start = domain[0];
        const end = domain[1];
        const timeSpan = end - start;
        const pixelsPerTick = 50; // Adjust this value to change tick density

        let interval;
        if (aggregation === 'daily') {
            if (width / timeSpan * (24 * 60 * 60 * 1000) > pixelsPerTick) {
                interval = d3.timeDay;
            } else {
                interval = d3.timeWeek;
            }
        } else if (aggregation === 'weekly') {
            if (width / timeSpan * (7 * 24 * 60 * 60 * 1000) > pixelsPerTick) {
                interval = d3.timeWeek;
            } else {
                interval = d3.timeMonth;
            }
        } else { // 'monthly'
            if (width / timeSpan * (30 * 24 * 60 * 60 * 1000) > pixelsPerTick) {
                interval = d3.timeMonth;
            } else {
                interval = d3.timeYear;
            }
        }

        return interval.range(start, end);
    };

    useEffect(() => {
        if (data && d3Container.current) {
            const svg = d3.select(d3Container.current);
            svg.selectAll("*").remove();

            const { width, height } = dimensions;
            const margin = { top: 20, right: 30, bottom: 50, left: 40 };
            const innerWidth = width - margin.left - margin.right;
            const innerHeight = height - margin.top - margin.bottom;

            const x = d3.scaleTime()
                .domain(d3.extent(data.labels, d => new Date(d)))
                .range([0, innerWidth]);

            const y = d3.scaleLinear()
                .domain([0, d3.max(data.datasets, d => d3.max(d.data, item => item.count))])
                .nice()
                .range([innerHeight, 0]);

            const line = d3.line()
                .x(d => x(new Date(d.date)))
                .y(d => y(d.count));

            const g = svg.append("g")
                .attr("transform", `translate(${margin.left},${margin.top})`);

            const xAxis = d3.axisBottom(x)
                .tickValues(getIntelligentTicks(x.domain(), innerWidth, timePeriod, aggregation))
                .tickFormat(d => {
                    if (aggregation === 'daily') {
                        return d3.timeFormat("%b %d")(d);
                    } else if (aggregation === 'weekly') {
                        return d3.timeFormat("%b %d")(d);
                    } else {
                        return d3.timeFormat("%b %Y")(d);
                    }
                });

            g.append("g")
                .attr("transform", `translate(0,${innerHeight})`)
                .call(xAxis)
                .selectAll("text")
                .style("text-anchor", "end")
                .attr("dx", "-.8em")
                .attr("dy", ".15em")
                .attr("transform", "rotate(-45)");

            g.append("g")
                .call(d3.axisLeft(y));

            data.datasets.forEach((dataset, index) => {
                g.append("path")
                    .datum(dataset.data)
                    .attr("fill", "none")
                    .attr("stroke", dataset.borderColor)
                    .attr("stroke-width", 1.5)
                    .attr("d", line);
            });

            // Add legend
            const legend = svg.append("g")
                .attr("font-family", "sans-serif")
                .attr("font-size", 10)
                .attr("text-anchor", "end")
                .selectAll("g")
                .data(data.datasets)
                .enter().append("g")
                .attr("transform", (d, i) => `translate(0,${i * 20})`);

            legend.append("rect")
                .attr("x", width - 19)
                .attr("width", 19)
                .attr("height", 19)
                .attr("fill", d => d.borderColor);

            legend.append("text")
                .attr("x", width - 24)
                .attr("y", 9.5)
                .attr("dy", "0.32em")
                .text(d => d.label);
        }
    }, [data, dimensions, timePeriod, aggregation]);

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <svg
                className="d3-component"
                width={dimensions.width}
                height={dimensions.height}
                ref={d3Container}
            />
        </div>
    );
};

export default UserStatisticsGraph;