import { SET_AUDIT_STATUS, SET_AUDIT_ERROR } from './Actions';
import { REHYDRATE } from 'redux-persist';

const initialState = {};

export default function auditReducer(state = initialState, action) {
  console.log('Processing action:', action.type, action.payload);
  
  switch (action.type) {
    case REHYDRATE:
      // Preserve existing audit data during rehydration
      return {
        ...state,
        ...(action.payload?.audits || {})
      };

    case SET_AUDIT_STATUS:
      const newState = {
        ...state,
        [action.payload.uuid]: {
          isPublic: action.payload.isPublic,
          auditStatus: action.payload.auditStatus,
          hasAccess: action.payload.hasAccess,
          isManager: action.payload.isManager,
          error: null,
          lastChecked: Date.now() // Add timestamp for cache invalidation
        }
      };
      console.log('New Redux state:', newState);
      return newState;

    case SET_AUDIT_ERROR:
      return {
        ...state,
        [action.payload.uuid]: {
          ...state[action.payload.uuid],
          error: action.payload.error,
          lastChecked: Date.now()
        }
      };

    default:
      return state;
  }
}
