import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import InviteAuditors from './InviteAuditors';
import ExportAuditResults from './ExportData';
import { useAuditAccess } from '../utils/AuditAccessContext';
import { clearUserAuthInfo } from '../features/user/userSlice.js';
import { useCsrfToken } from '../utils/CsrfTokenContext';

import './GlobalSidebar.css';

const GlobalSidebar = ({ onLockToggle }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isAuditView = /\/[a-f0-9-]+\/(audit_summary|flags|dependencies|files)/.test(location.pathname);

  const [expanded, setExpanded] = useState(false);
  const [locked, setLocked] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const { hasAccess, isManager, auditUuid } = useAuditAccess();
  const isAdmin = useSelector(state => state.user.isAdmin);
  const dispatch = useDispatch();

  const { csrfToken } = useCsrfToken();

  useEffect(() => {
    const timer = setTimeout(() => {
      setLocked(true);
    }, 5000);

    return () => clearTimeout(timer);
  });

  const toggleSidebar = () => {
    if (!locked) {
      setExpanded(prevExpanded => !prevExpanded);
    }
  };

  const lockSidebar = () => {
    const newLockedState = !locked;
    setLocked(newLockedState);
    if (onLockToggle) {
      onLockToggle(newLockedState);
    }
  };

  const openInviteModal = () => {
    setShowInviteModal(true);
  };

  const closeInviteModal = () => {
    setShowInviteModal(false);
  };

  const openExportModal = () => {
    if (!auditUuid) {
      console.error('No audit UUID available');
      return;
    }
    setShowExportModal(true);
  };

  const closeExportModal = () => {
    setShowExportModal(false);
  };

  const handleLogout = async () => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/django_codedd/logout/`, 
            {},
            {
                headers: {
                    'X-CSRFToken': csrfToken,
                },
                withCredentials: true
            }
        );

        if (response.data.status === 'success') {
            localStorage.clear();
            dispatch(clearUserAuthInfo());
            
            // Clear cookies
            document.cookie.split(";").forEach((c) => {
                document.cookie = c
                    .replace(/^ +/, "")
                    .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
            });
            
            navigate('/', { replace: true });
        } else {
            console.error('Logout failed:', response.data.message);
            // Force logout even on failure
            localStorage.clear();
            dispatch(clearUserAuthInfo());
            navigate('/', { replace: true });
        }
    } catch (error) {
        console.error('Error during logout:', error);
        // Force logout even on error
        localStorage.clear();
        dispatch(clearUserAuthInfo());
        navigate('/', { replace: true });
    }
};

  const SidebarButton = ({ icon, label, onClick, className }) => (
    <button className={`sidebar-button ${className}`} onClick={onClick}>
      <i className={`pi ${icon}`}></i>
      <span className="button-label">{label}</span>
    </button>
  );

  return (
    <div 
      className={`global-sidebar ${expanded ? 'expanded' : ''}`}
      onMouseEnter={toggleSidebar}
      onMouseLeave={toggleSidebar}
    >
      <div className="logo-container-sidebar">
        <Link to="/dashboard">
          <img src="/logo192.png" alt="Logo" className="sidebar-logo" />
        </Link>
      </div>

      <SidebarButton
        icon="pi-home"
        label="Dashboard"
        onClick={() => navigate('/dashboard')}
        className="dashboard-button"
      />

      {isManager && isAuditView && auditUuid && (
        <SidebarButton
          icon="pi-users"
          label="Manage Access"
          onClick={openInviteModal}
          className="manage-access-button"
        />
      )}

      {hasAccess && auditUuid && (
      <SidebarButton
        icon="pi-download"
        label="Export Data"
        onClick={openExportModal}
        className="export-data-button"
      />
      )}

      <div className="bottom-section">
        {isAdmin === true && (
          <SidebarButton
            icon="pi-android"
            label="Admin Panel"
            onClick={() => navigate('/codedd-admin')}
            className="admin-button"
            style={{ color: '#C049DE' }}
          />
        )}
        <SidebarButton
          icon="pi-user"
          label="Account"
          onClick={() => navigate('/account')}
          className="account-button"
        />
        <SidebarButton
          icon="pi-sign-out"
          label="Logout"
          onClick={handleLogout}
          className="logout-button"
        />
      </div>

      <Button
        icon={locked ? 'pi pi-lock' : 'pi pi-lock-open'}
        className="lock-button"
        onClick={lockSidebar}
      />

      <Dialog 
        className="manage-access-modal" 
        visible={showInviteModal} 
        onHide={closeInviteModal} 
        header="Manage Audit Access" 
        draggable={false}
        style={{ minWidth: '350px' }}
      >
        <InviteAuditors closeModal={closeInviteModal} />
      </Dialog>

      <Dialog 
        className="exports-modal" 
        visible={showExportModal} 
        onHide={closeExportModal} 
        header="Export Audit Results" 
        draggable={false}
        style={{ minWidth: '350px' }}
      >
        <ExportAuditResults 
          closeModal={closeExportModal}
          auditUuid={auditUuid}
        />
      </Dialog>
    </div>
  );
};

export default GlobalSidebar;