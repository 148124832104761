import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams, Navigate } from 'react-router-dom';  // Import Navigate for redirection
import { useDispatch, useSelector } from 'react-redux';  // Import useDispatch and useSelector
import { checkAuditStatus } from '../utils/Actions';  // Import checkAuditStatus action

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Message } from 'primereact/message';
import { Paginator } from 'primereact/paginator';
import { Panel } from 'primereact/panel';
import { Badge } from 'primereact/badge';

import GlobalSidebar from '../components/GlobalSidebar';
import TabMenuComponent from '../components/TabMenu';
import Header from '../components/Header';
import DependencyGraph from './DependenciesGraph'; // This would be your D3.js component

import './Dependencies.css';

const domainLogos = {
    "github.com": '/images/GitHub.png',
    "gitlab.com": '/images/GitLab.png',
    "bitbucket.org": "/images/Bitbucket.png",
    "SourceForge.net": "/images/SourceForge.png",
    "code.google.com": "/images/GoogleCode.png",
    "codeplex.com": "/images/CodePlex.png",
    "launchpad.net": "/images/Launchpad.png",
    "savannah.gnu.org": "/images/Savannah.png",
    "freecode.com": "/images/Freecode.png",
    "gitkraken.com": "/images/GitKraken.png",
    "beanstalkapp.com": "/images/Beanstalk.png",
    "assembla.com": "/images/Assembla.png",
    "phabricator.com": "/images/Phabricator.png",
    "gogs.io": "/images/Gogs.png",
    "gitea.com": "/images/Gitea.png",
    "gitbucket.com": "/images/GitBucket.png",
    "codeberg.org": "/images/Codeberg.png",
      // ... other domains if necessary
  };

function DependenciesView() {
  const [dependenciesData, setDependenciesData] = useState({ sorted_packages: [] });

  const { auditUuid } = useParams();
  const dispatch = useDispatch();
  const auditAccessDetails = useSelector(state => state.audits[auditUuid]);
  const isMounted = useRef(true);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const [fileCount, setFileCount] = useState(0);
  const [auditName, setAuditName] = useState(''); // State for audit name
  const [repoUrl, setRepoUrl] = useState(''); // State for repo URL
  const [repoDomain, setRepoDomain] = useState(''); // State for repo domain
  const [isGraphVisible, setIsGraphVisible] = useState(true);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const [totalDependencies, setTotalDependencies] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);  // Rows per page can be adjusted or made dynamic
  const [isGraphLoading, setIsGraphLoading] = useState(true);
  const [unusedCount, setUnusedCount] = useState(0);


  useEffect(() => {
    if (!auditAccessDetails) {
      console.log("Audit details are not available yet.");
    } else if (auditAccessDetails.error) {
      console.error('Audit access error:', auditAccessDetails.error);
      setShouldRedirect(true);
    } else if (!auditAccessDetails.hasAccess) {
      setShouldRedirect(true);
    } else {
      setShouldRedirect(false);
    }
  }, [auditAccessDetails]);

  useEffect(() => {
    dispatch(checkAuditStatus(auditUuid));
  }, [dispatch, auditUuid]);
  
  
  useEffect(() => {
    // Fetch audit details
    axios.get(`${process.env.REACT_APP_API_URL}/django_codedd/audit_details/${auditUuid}/`)
      .then(response => {
        if (isMounted.current) {
          const data = response.data;
          setAuditName(data.name);
          setRepoUrl(data.full_url);
          setFileCount(data.file_count);

          const domain = data.url && new URL(data.full_url).hostname;
          setRepoDomain(domain || 'Unknown');
          setIsDataLoaded(true);
        }
      })
      .catch(error => {
        if (isMounted.current) {
          console.error('Error fetching audit details:', error);
          setIsDataLoaded(true);
        }
      });

    // Fetch dependencies
    if (!auditAccessDetails || !auditAccessDetails.hasAccess) return;
    const offset = (currentPage - 1) * rowsPerPage;

    setIsGraphLoading(true);

    axios.get(`${process.env.REACT_APP_API_URL}/django_codedd/dependencies/${auditUuid}/`, {
      params: {
        offset: offset,
        limit: rowsPerPage
      }
    })
      .then(response => {
        if (isMounted.current) {
          console.log('Received dependencies data:', response.data);
          const cleanedPackages = response.data.sorted_packages.map(pkg => {
            console.log('Processing package:', pkg);  // Log each package
            return {
              ...pkg,
              package_name: removePackagePrefix(pkg.package_name)
            };
          });
        setTotalDependencies(response.data.total_count); // Set total dependencies count
        const mergedData = mergePackageDetails(
          response.data.dependency_graph,
          cleanedPackages
        );
        setDependenciesData({
          dependency_graph: mergedData,
          sorted_packages: cleanedPackages
        });
        setUnusedCount(response.data.unused_count);

        setIsGraphLoading(false);
      }
    })
    .catch(error => {
      if (isMounted.current) {
        console.error('Error fetching dependencies:', error);
      }
      setIsGraphLoading(false);
    });
  }, [auditUuid, currentPage, rowsPerPage, auditAccessDetails]);

  if (shouldRedirect) {
    return <Navigate to="/" />;
  }

// Helper function to merge package details into dependency graph nodes
const mergePackageDetails = (dependencyGraph, sortedPackages) => {
  const nodesWithDetails = dependencyGraph.nodes.map(node => {
    if (node.type === 'package') {
      const packageDetails = sortedPackages.find(pkg => removePackagePrefix(pkg.name) === node.name);
      return { ...node, ...packageDetails };
    }
    return node;
  });

  return {
    ...dependencyGraph,
    nodes: nodesWithDetails,
  };
};

  if (!dependenciesData) {
    return (
      <div>
        <GlobalSidebar />
      </div>
    );
  }

  const headerProps = isDataLoaded ? {
    auditName: auditName,
    fileCount: fileCount,
    repoUrl: repoUrl,
    repoDomain: repoDomain,
    domainLogos: domainLogos
  } : {};


  const isGraphDataLoaded = dependenciesData && dependenciesData.dependency_graph &&
                            Array.isArray(dependenciesData.dependency_graph.nodes) &&
                            Array.isArray(dependenciesData.dependency_graph.edges) &&
                            dependenciesData.dependency_graph.nodes.length > 0 &&
                            dependenciesData.dependency_graph.edges.length > 0;


  const toggleGraphPanel = (event) => {
    setIsGraphVisible(prevState => !prevState);
  };

  // Helper function for text truncation and styling
  const truncateText = (text) => {
    if (text && text.length > 120) {  // Check if text is not undefined and has length greater than 120
      return text.substring(0, text.lastIndexOf(' ', 120)) + '...';
    }
    return text || '';  // Return an empty string if text is undefined or null
  };

  // General column template for truncating text
  const textTemplate = (rowData, field) => {
    const text = rowData[field];
    return <span>{truncateText(text)}</span>;
  };

  // Column templates
  const packageTemplate = (rowData) => {
    if (rowData.project_url) {
      return <a href={rowData.project_url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>
        {truncateText(rowData.package_name)}
      </a>;
    }
    return truncateText(rowData.package_name);
  };

  const repositoryTemplate = (rowData) => {
    if (rowData.repository_url) {
      return <a href={rowData.repository_url} target="_blank" rel="noopener noreferrer">Link</a>;
    }
    return null;
  };

  const licenseTemplate = (rowData) => {
    if (rowData.licence_url) {
      return <a href={rowData.licence_url} target="_blank" rel="noopener noreferrer">{rowData.licence}</a>;
    }
    return rowData.licence;
  };

  const authorLink = (rowData) => {
    // Priority to author URL if available
    if (rowData.author_url) {
      return <a href={rowData.author_url} target="_blank" rel="noopener noreferrer">{rowData.author}</a>;
    }
    // Fallback to email if URL is not present
    else if (rowData.email) {
      return <a href={`mailto:${rowData.email}`} target="_blank" rel="noopener noreferrer">{rowData.author}</a>;
    }
    // If neither URL nor email is present, just return the author's name
    return rowData.author;
  };

  const maintenanceStatusTemplate = (rowData) => {
    console.log('Maintenance status data:', rowData);
    const badges = [];
    
    if (rowData.is_deprecated) {
      badges.push(
        <div key="deprecated" className="badge-tooltip-container">
          <Badge 
            value="Deprecated" 
            severity="danger" 
            className="mr-2" 
          />
          <div className="badge-tooltip">
            This package has been marked as deprecated by its maintainers
          </div>
        </div>
      );
    }
    
    if (rowData.is_outdated && rowData.versions_behind) {
      const versionsBehind = rowData.versions_behind;
      const versionText = [];
      if (versionsBehind.major > 0) {
        versionText.push(`${versionsBehind.major} major`);
      }
      if (versionsBehind.minor > 0) {
        versionText.push(`${versionsBehind.minor} minor`);
      }
      const versionString = versionText.length > 0 
        ? `${versionText.join(' and ')} versions behind the latest release`
        : 'New version available';

      badges.push(
        <div key="outdated" className="badge-tooltip-container">
          <Badge 
            value="Outdated" 
            severity="warning" 
            className="mr-2" 
          />
          <div className="badge-tooltip">
            {versionString}
          </div>
        </div>
      );
    }
    
    if (rowData.months_since_update && rowData.months_since_update > 12) {
      badges.push(
        <div key="unmaintained" className="badge-tooltip-container">
          <Badge 
            value="Unmaintained" 
            severity="warning" 
            className="mr-2" 
          />
          <div className="badge-tooltip">
            {`Last updated ${Math.floor(rowData.months_since_update)} months ago`}
          </div>
        </div>
      );
    }
    
    return <div className="badge-container">{badges}</div>;
  };

  const getDynamicColumns = () => {
    const allKeys = new Set();
    dependenciesData.sorted_packages.forEach(pkg => {
      Object.keys(pkg).forEach(key => allKeys.add(key));
    });
  
    const excludedKeys = new Set([
      'is_deprecated', 
      'is_outdated', 
      'months_since_update', 
      'is_dev', 
      'dependency_type', 
      'project_url', 
      'licence_url', 
      'repository_url', 
      'name', 
      'email', 
      'upload_time', 
      'author_url',
      'versions_behind'  // Add this to excluded keys
    ]);
  
    const renameMapping = {
      'usage_count': 'Times Used',
      'latest_version': 'Latest Version',
      'package_name': 'Package Name',
      'version': 'Version'
    };
  
    const desiredOrder = [
      'Package Name',
      'Times Used',
      'Version',
      'Latest Version',
      'Status',
      'Author',
      'Licence',
      'Summary',
      'Description'
    ];
  
    const dynamicColumns = Array.from(allKeys)
      .filter(key => !excludedKeys.has(key))
      .map(key => ({
        field: key,
        header: renameMapping[key] || key.charAt(0).toUpperCase() + key.slice(1),
        body: (rowData) => {
          if (key === 'package_name') {
            return packageTemplate(rowData);
          } else if (key === 'licence') {
            return licenseTemplate(rowData);
          } else if (key === 'repository_url') {
            return repositoryTemplate(rowData);
          } else if (key === 'author') {
            return authorLink(rowData);
          } else {
            return textTemplate(rowData, key);
          }
        }
      }));
  
    dynamicColumns.sort((a, b) => {
      const indexA = desiredOrder.indexOf(a.header);
      const indexB = desiredOrder.indexOf(b.header);
      return indexA - indexB;
    });
  
    dynamicColumns.push({
      field: 'maintenance_status',
      header: 'Status',
      body: maintenanceStatusTemplate
    });
  
    return dynamicColumns;
  };

  const removePackagePrefix = (packageName) => {
    const prefixPattern = /\$\![a-z]+\$!\_/i; // Regex to match the prefix pattern
    return packageName.replace(prefixPattern, ''); // Remove the prefix and return the name
  };

  const onPaginatorChange = (e) => {
    setCurrentPage(e.page + 1);  // Paginator component uses zero-based index for pages
    setRowsPerPage(e.rows);

  };


  const renderDependenciesTable = () => {
    if (!isDataLoaded) {
      return null;
    }

    if (!dependenciesData || !dependenciesData.sorted_packages || dependenciesData.sorted_packages.length === 0) {
      return <Message severity="info" text="No dependencies found." />;
    }
  
    const dynamicColumns = getDynamicColumns();
  
    return (
      <>
        <DataTable value={dependenciesData.sorted_packages} stripedRows>
          {dynamicColumns.map((col, i) => (
            <Column key={i} field={col.field} header={col.header} body={col.body} />
          ))}
        </DataTable>
        <Paginator 
          first={(currentPage - 1) * rowsPerPage} 
          rows={rowsPerPage}
          totalRecords={totalDependencies}
          onPageChange={onPaginatorChange}
          rowsPerPageOptions={[10, 20, 50]} />
      </>
    );
  };

  return (
    <div>
      <GlobalSidebar />
      <Header {...headerProps} />
      <TabMenuComponent />
      <div className="dependencies-page-layout">
        <div className="dependency-header">
          <p>
            In total <strong>{totalDependencies || 0}</strong> packages were found within {fileCount} files screened.
          </p>
          {unusedCount > 0 && (
            <p className="unused-packages-warning">
              <i className="pi pi-exclamation-triangle" style={{ marginRight: '8px', color: '#f59e0b' }}></i>
              <strong>{unusedCount}</strong> packages are declared but were not found in the code. These could potentially be removed.
            </p>
          )}
        </div>
        <div className="dependencies-container">
          {isDataLoaded ? renderDependenciesTable() : 'Loading...'}
        </div>
        <Panel className="dependency-graph" header="Dependency Graph" toggleable collapsed={!isGraphVisible} onToggle={toggleGraphPanel}>
        {isGraphVisible && isGraphLoading && (
          <div className="dependency-loader-container">
            <div className="dependencygraph-loader"></div>
          </div>
        )}
        {isGraphVisible && !isGraphLoading && <DependencyGraph dependencies={dependenciesData.dependency_graph} />}
        </Panel>
      </div>
    </div>
  );
}

export default DependenciesView;
