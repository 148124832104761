import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import axios from 'axios';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import { codeExamples } from './examples/codeExamples';
import './TryDemo.css';
import { Carousel } from 'primereact/carousel';
import ScoreGraph from './DemoScoresGraph';
import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { python } from '@codemirror/lang-python';

/**
 * Handles different types of errors and displays appropriate toast messages
 * @param {Error} error - The error object to handle
 * @param {React.RefObject} toastRef - Reference to the Toast component
 */
const handleError = (error, toastRef) => {
    let errorMessage = 'An unexpected error occurred';
    let errorSummary = 'Error';

    if (axios.isAxiosError(error)) {
        // Handle network or axios specific errors
        if (error.code === 'ECONNABORTED') {
            errorMessage = 'Request timed out. Please try again.';
            errorSummary = 'Timeout Error';
        } else if (!error.response) {
            errorMessage = 'Network error. Please check your connection.';
            errorSummary = 'Network Error';
        } else {
            // Handle specific HTTP status codes
            switch (error.response.status) {
                case 400:
                    errorMessage = error.response.data?.message || 'Invalid request. Please check your code.';
                    errorSummary = 'Invalid Request';
                    break;
                case 429:
                    errorMessage = 'Too many requests. Please wait a moment.';
                    errorSummary = 'Rate Limit Exceeded';
                    break;
                case 500:
                    errorMessage = 'Server error. Please try again later.';
                    errorSummary = 'Server Error';
                    break;
                default:
                    errorMessage = error.response.data?.message || 'An error occurred while processing your request.';
            }
        }
    } else if (error instanceof Error) {
        errorMessage = error.message;
    }

    toastRef.current?.show({
        severity: 'error',
        summary: errorSummary,
        detail: errorMessage,
        life: 5000
    });
};

const CodeEditor = ({ value, language, onChange }) => {
    const getLanguageExtension = (lang) => {
        switch (lang.toLowerCase()) {
            case 'javascript':
                return javascript();
            case 'python':
                return python();
            default:
                return javascript(); // fallback to javascript
        }
    };

    return (
        <CodeMirror
            value={value}
            height="320px"
            theme="light"
            extensions={[getLanguageExtension(language)]}
            onChange={(value) => onChange(value)}
            style={{
                fontSize: '14px',
                borderRadius: '4px',
                border: '1px solid var(--surface-border)'
            }}
        />
    );
};

const AuditResults = ({ results }) => {
    if (!results.is_valid_code) {
        return (
            <div className="audit-invalid-code">
                <i className="pi pi-exclamation-triangle" style={{ fontSize: '2rem', color: '#E83636' }} />
                <h3 style={{color: '#E83636'}}>Invalid Code Detected</h3>
                <p>{results.message}</p>
            </div>
        );
    }

    const { audit_result } = results;
    const { qualitative_data, parameters, section_scores } = audit_result;

    return (
        <div className="audit-results">
            {/* Validation Message Section */}
            <div className="validation-message-container">
                <Card className="validation-message">
                    <p>{results.validation_message}</p>
                </Card>
            </div>

            {/* Graph Section */}
            <div className="graph-section">
                <h4>This is an average representation of all the parameters analyzed from each section.</h4>
                <h4>You can hover over each section to see a more detailed breakdown.</h4>
                <div className="graph-frame">
                    <ScoreGraph
                        scoresData={[
                            { title: 'Quality', scoreAttribute: 'overall_quality_score' },
                            { title: 'Functionality', scoreAttribute: 'overall_functionality_score' },
                            { title: 'Scalability', scoreAttribute: 'overall_performance_score' },
                            { title: 'Security', scoreAttribute: 'overall_security_score' },
                            { title: 'Compatibility', scoreAttribute: 'overall_architecture_score' },
                            { title: 'Documentation', scoreAttribute: 'overall_documentation_score' },
                            { title: 'Standards', scoreAttribute: 'overall_standards_score' },
                        ]}
                        auditDetails={section_scores}
                        parameters={parameters}
                    />
                </div>
            </div>

            {/* Qualitative Analysis Section */}
            <div className="qualitative-analysis">
                <Card className="analysis-card">
                    <h3>Script Purpose</h3>
                    <p>{qualitative_data.script_purpose}</p>
                </Card>

                <Card className="analysis-card">
                    <h3>Summary</h3>
                    <p>{qualitative_data.summary_all}</p>
                </Card>

                <Card className="analysis-card">
                    <h3>Recommendations</h3>
                    <p>{qualitative_data.recommendation}</p>
                </Card>
            </div>

            {/* Tags Section */}
            <div className="tags-section">
                <h3>Tags</h3>
                <div className="tags-container">
                    {qualitative_data.tags.split(',').map((tag, index) => (
                        <span key={index} className="tag-badge">
                            {tag.trim()}
                        </span>
                    ))}
                </div>
            </div>
        </div>
    );
};

const TryDemo = ({ maxCodeLength = 5000, rateLimitMs = 1000 }) => {
    const [snippet, setSnippet] = useState('');
    const [currentLanguage, setCurrentLanguage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [lastSubmissionTime, setLastSubmissionTime] = useState(0);
    const toast = useRef(null);
    const [auditResults, setAuditResults] = useState(null);
    const resultsRef = useRef(null);

    const handleLanguageSelect = useCallback((language, code) => {
        setCurrentLanguage(language.toLowerCase());
        setSnippet(code);
    }, []);

    const validateCode = useCallback((code) => {
        if (!code.trim()) {
            toast.current?.show({
                severity: 'warn',
                summary: 'Warning',
                detail: 'Please enter some code before submitting',
                life: 3000
            });
            return false;
        }

        if (code.length > maxCodeLength) {
            toast.current?.show({
                severity: 'error',
                summary: 'Error',
                detail: `Code exceeds maximum length of ${maxCodeLength} characters`,
                life: 3000
            });
            return false;
        }

        return true;
    }, [maxCodeLength]);

    const scrollToResults = () => {
        if (resultsRef.current) {
            resultsRef.current.scrollIntoView({ 
                behavior: 'smooth',
                block: 'start'
            });
        }
    };

    const handleSubmit = async () => {
        // Rate limiting check
        const now = Date.now();
        if (now - lastSubmissionTime < rateLimitMs) {
            toast.current?.show({
                severity: 'warn',
                summary: 'Please wait',
                detail: 'Please wait a moment before submitting again',
                life: 3000
            });
            return;
        }

        if (!validateCode(snippet)) {
            return;
        }

        setIsLoading(true);
        setLastSubmissionTime(now);
        setAuditResults(null);

        try {
            const sanitizedSnippet = DOMPurify.sanitize(snippet);
            
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/django_codedd/try_demo/`,
                { snippet: sanitizedSnippet },
                {
                    timeout: 30000, // 30 second timeout
                    headers: { 'Content-Type': 'application/json' }
                }
            );
            
            if (response.data.error) {
                throw new Error(response.data.error);
            }

            setAuditResults(response.data);
            
            toast.current?.show({
                severity: 'success',
                summary: 'Success',
                detail: 'Code audit completed successfully',
                life: 3000
            });

            setTimeout(scrollToResults, 100);
            
        } catch (error) {
            handleError(error, toast);
        } finally {
            setIsLoading(false);
        }
    };

    const responsiveOptions = [
        {
            breakpoint: '1400px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '1199px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 1,
            numScroll: 1
        }
    ];

    const languageTemplate = (example) => {
        return (
            <Card 
                className="language-card"
                style={{ height: '155px' , backgroundColor: '#faf9f8'}}
                onClick={() => handleLanguageSelect(example.language, example.code)}
            >
                <div className="language-card-content">
                    <div className="language-header">
                        <img 
                            src={example.icon}
                            alt={`${example.language} logo`}
                            className="language-icon"
                        />
                        <span className="language-name">{example.language}</span>
                    </div>
                    <p className="language-description">{example.description}</p>
                </div>
            </Card>
        );
    };

    return (
        <div className="try-demo-container" role="main">
            <Toast ref={toast} />
            <h2>Try Codedd Demo</h2>
            <h4 className="try-demo-subtitle">
                You can try out the Codedd code analysis tool with a code snippet of your choice.
            </h4>
            <h4 className="try-demo-subtitle">
                Simply paste a code snippet or choose one of the presets.
            </h4>
            
            <div className="carousel-container">
                <Carousel 
                    value={Object.values(codeExamples)} 
                    numScroll={1} 
                    numVisible={3} 
                    responsiveOptions={responsiveOptions} 
                    itemTemplate={languageTemplate}
                    className="language-carousel"
                />
            </div>

            <div className="code-editor-wrapper">
                <CodeEditor
                    value={snippet}
                    language={currentLanguage}
                    onChange={(newValue) => setSnippet(newValue)}
                />
                <div className="character-count">
                    {snippet.length} / {maxCodeLength} characters
                </div>
            </div>
            <div className="submit-button-wrapper">
                <Button 
                    label={isLoading ? 'Analyzing...' : 'Analyze Code'}
                    icon={isLoading ? null : 'pi pi-search'}
                    onClick={handleSubmit}
                    className="p-button-primary"
                    disabled={isLoading}
                    aria-busy={isLoading}
                >
                    {isLoading && <ProgressSpinner style={{ width: '20px', height: '20px' }} />}
                </Button>
            </div>

            {auditResults && (
                <div 
                    ref={resultsRef} 
                    className="audit-results-container"
                >
                    <AuditResults results={auditResults} />
                </div>
            )}
        </div>
    );
};

// PropTypes for runtime type checking
TryDemo.propTypes = {
    maxCodeLength: PropTypes.number,
    rateLimitMs: PropTypes.number
};

export default TryDemo; 