import React, { useEffect, useState, useRef } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import axios from 'axios';
import './AuditInvitationSummaryTab.css';
import { useCsrfToken } from '../../utils/CsrfTokenContext';

const AuditInvitationSummaryTab = ({ visible, onHide, userRole, repositoriesData, groupAuditUuid, userName, fetchRepositories, setAuditStarted, auditStarted, setAuditFinished, auditFinished }) => {
    const { csrfToken } = useCsrfToken();
    const [isLoading, setIsLoading] = useState(false);
    const [locBudget, setLocBudget] = useState(0);
    const [readyForAudit, setReadyForAudit] = useState(false);
    const [isAuditInitiating, setIsAuditInitiating] = useState(false);
    const toast = useRef(null);
    const [accountUuid, setAccountUuid] = useState("");
    const intervalIdRef = useRef(null);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [locBudgetLocked, setLocBudgetLocked] = useState(false);
    
    useEffect(() => {
        const persistRoot = JSON.parse(localStorage.getItem('persist:root'));
        const user = JSON.parse(persistRoot?.user || '{}');
        const fetchedAccountUuid = user.accountUuid || "";
    
        // Define fetchLocBudget inside useEffect to ensure it has access to the updated accountUuid
        const fetchLocBudget = async (accountUuid) => {
            if (!accountUuid) {
                console.error('Account UUID not found in local storage');
                return;
            }
        
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/django_codedd/api/get_loc_buget/?account_uuid=${accountUuid}`);
                setLocBudget(response.data.loc_budget);
            } catch (error) {
                console.error('Error fetching LOC budget:', error);
            }
        };
    
        if (fetchedAccountUuid) {
            setAccountUuid(fetchedAccountUuid); // Update state
            fetchLocBudget(fetchedAccountUuid); // Fetch LOC budget with the newly fetched accountUuid
        }
    
        startAuditReadyStatusPolling();
        return () => {
            if (intervalIdRef.current) {
                clearInterval(intervalIdRef.current);
            }
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsCollapsed(window.innerWidth < 1075);
        };

        // Set initial state
        handleResize();

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    
    const checkGroupAuditStatus = async () => {
        try {
            // Adjust the API call to include groupAuditUuid as a query parameter
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/django_codedd/get_audit_ready_status/?groupAuditUuid=${encodeURIComponent(groupAuditUuid)}`);
            setReadyForAudit(response.data.status === 'Group audit ready');
            setAuditStarted(response.data.status === 'Group audit started');
            setAuditFinished(response.data.status === 'Group audit completed');
        } catch (error) {
            console.error('Error fetching audit ready status:', error);
            setReadyForAudit(false); // Optionally handle error by setting audit not ready
        }
    };

    
    const startAuditReadyStatusPolling = () => {
        checkGroupAuditStatus(); // Initial check
        const intervalId = setInterval(checkGroupAuditStatus, 30000); // Poll every 30 seconds
        intervalIdRef.current = intervalId; // Store interval ID
        return intervalId;
    };
    

    const handleSetReadyForAudit = async () => {
        setIsLoading(true);
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/django_codedd/audit_invitation_ready/`, { groupAuditUuid: groupAuditUuid }, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken
                },
                withCredentials: true
            });

            toast.current.show({ severity: 'success', summary: 'Audit marked as ready', detail: 'Emails have been sent', life: 3000 });
            setReadyForAudit(true);
        } catch (error) {
            console.error('Error in setting audit ready:', error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to set audit ready', life: 3000 });
        } finally {
            setIsLoading(false);
        }
    };

    const onStartAuditClick = async () => {
        if (!accountUuid) {
            console.error('Account UUID not found in local storage');
            return;
        }

        setIsLoading(true);
        setIsAuditInitiating(true);
        setAuditStarted(true);
        setLocBudgetLocked(true);

        try {
            // Send group audit confirmation email
            await axios.post(`${process.env.REACT_APP_API_URL}/django_codedd/send_group_audit_confirmation_email/`, {
                groupAuditUuid: groupAuditUuid
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken
                },
                withCredentials: true
            });
            
            // Trigger the main audit process and wait for it to complete
            await axios.post(`${process.env.REACT_APP_API_URL}/django_codedd/api/trigger_main_process/`, {
                groupAuditUuid: groupAuditUuid,
                accountUuid: accountUuid
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken
                },
                withCredentials: true
            });

            // Immediately execute functions after triggering the main process
            fetchRepositories();
            checkGroupAuditStatus();       

        } catch (error) {
            console.error('Error in audit process or sending email:', error);
        } finally {
            setIsLoading(false); // Reset loading state regardless of the audit outcome
        }

        try {
            axios.post(`${process.env.REACT_APP_API_URL}/django_codedd/api/update_loc_budget/`, {
                accountUuid: accountUuid, // Ensure this is the actual accountUuid from your storage or state
                linesOfCodeUsed: repositoriesData.total_lines_of_code
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken 
                },
                withCredentials: true
            });
            console.log('locBudget updated successfully');
        } catch (error) {
            console.error('Error updating locBudget:', error);
        }
    };

    const toggleSidebar = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <div className={`sidebar-container ${isCollapsed ? 'collapsed' : ''}`}>
            <Sidebar visible={visible} modal={false} position="right" className={`responsive-sidebar ${isCollapsed ? 'collapsed' : ''}`} onHide={() => {}}>
            <Button 
                icon={`pi ${isCollapsed ? 'pi-angle-left' : 'pi-angle-right'}`}
                className="p-button-rounded p-button-text toggle-button"
                onClick={toggleSidebar}
            />
                {!isCollapsed && (
                    <>
                        <Toast ref={toast} />
                        <div className="audit-summary">
                            <h2>Audit scope</h2>
                            <div className="repositories-to-audit">
                                <span><strong>{(repositoriesData.number_of_repos || 0).toLocaleString('de-DE')}</strong></span>
                                <label> repositories</label>
                            </div>
                            <div className="files-to-audit">
                                <span><strong>{(repositoriesData.total_number_files || 0).toLocaleString('de-DE')}</strong></span>
                                <label> files</label>
                            </div>
                            <div className="lines-of-code">
                                <span><strong>{(repositoriesData.total_lines_of_code || 0).toLocaleString('de-DE')}</strong></span>
                                <label> total lines of code</label>
                            </div>

                            {userRole === 'inviter' && repositoriesData.number_of_repos === 0 && !readyForAudit && (
                                <div className="confirmation-message">
                                    <h4>It is the turn of the repository owner to add repositories.</h4>
                                </div>
                            )}

                            {userRole === 'invitee' && repositoriesData.number_of_repos > 0 && (
                                <div className="confirmation-message">
                                    <h4>Mark the audit list as ready to notify {userName} to start the audit.</h4>
                                </div>
                            )}

                            {userRole === 'invitee' && repositoriesData.number_of_repos > 0 && (
                                <div>
                                <Button 
                                    label={<span style={{ color: 'white', fontSize: '1rem' }}>Set group audit ready</span>}
                                    onClick={handleSetReadyForAudit}
                                    icon={isLoading ? 'pi pi-spin pi-spinner' : 'pi pi-check'}
                                    className={`p-button-sm set-group-audit-ready ${readyForAudit || isLoading || auditStarted || auditFinished ? 'disabled' : ''}`}
                                    disabled={readyForAudit || isLoading || auditStarted || auditFinished}
                                />
                                </div>
                            )}
                            {userRole === 'invitee' && readyForAudit && (
                                <div className="confirmation-message">
                                    <h4>🚀 Thank you for setting the audit scope 🎉</h4>
                                    <h4>You will receive an email as soon as the audit started.</h4>
                                </div>
                            )}
                            {userRole === 'inviter' && readyForAudit && (
                                <div className="confirmation-message">
                                    <h4>🚀 Your audit is set and ready 🎉</h4>
                                    <h4>You can start the audit process now or adapt the scope, if needed.</h4>
                                </div>
                            )}
                        </div>

                        {/* Checkout section */}
                        {userRole === 'inviter' && (readyForAudit || auditStarted || auditFinished) && (
                            <div className="audit-order">
                                <h2>Checkout</h2>
                                
                                <div className="audit-info-row">
                                    <span>Free LoC:</span>
                                    <span className="audit-value"><b>{locBudget}</b></span>
                                </div>
                                
                                <div className="audit-info-row audit-info-row-small">
                                    <span>After this audit:</span>
                                    <span className="audit-value">{Math.max(0, locBudget - repositoriesData.total_lines_of_code)}</span>
                                </div>
                                
                                <div className="audit-info-row">
                                    <span>From selection:</span>
                                    <span className="audit-value"><b>{repositoriesData.total_lines_of_code}</b></span>
                                </div>
                                
                                <hr className="audit-divider"/>
                                
                                <div className="audit-info-row">
                                    <span>Payable LoC:</span>
                                    <span className="audit-value"><b>{Math.max(0, repositoriesData.total_lines_of_code - locBudget)}</b></span>
                                </div>
                                
                                <Button 
                                label={'Start Audit'}
                                className={`start-audit-button ${isLoading || (!locBudgetLocked && locBudget < repositoriesData.total_lines_of_code) || auditFinished || auditStarted || isAuditInitiating ? 'disabled' : ''}`}
                                onClick={onStartAuditClick}
                                icon={isLoading ? 'pi pi-spin pi-spinner' : 'pi pi-bolt'}
                                disabled={isLoading || (!locBudgetLocked && locBudget < repositoriesData.total_lines_of_code) || auditFinished || auditStarted || isAuditInitiating}
                                />
                                {!locBudgetLocked && locBudget < repositoriesData.total_lines_of_code && 
                                    <div className="audit-warning">Insufficient LOC budget to start the audit.</div>
                                }
                            </div>
                        )}
                    </>
                )}
            </Sidebar>
        </div>
    );
};

export default AuditInvitationSummaryTab;