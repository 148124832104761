import React, { createContext, useState, useContext, useEffect, useRef } from 'react';
import axios from 'axios';

const CsrfTokenContext = createContext();

export const useCsrfToken = () => useContext(CsrfTokenContext);

export const CsrfTokenProvider = ({ children }) => {
  const [csrfToken, setCsrfToken] = useState('');
  const interceptorRef = useRef(null);

  const refreshCsrfToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/django_codedd/set_csrf_token/`, 
        { withCredentials: true }
      );
      setCsrfToken(response.data.csrfToken);
      
      if (interceptorRef.current !== null) {
        axios.interceptors.request.eject(interceptorRef.current);
      }
      
      interceptorRef.current = axios.interceptors.request.use(config => {
        config.headers['X-CSRFToken'] = response.data.csrfToken;
        return config;
      });
    } catch (error) {
      console.error('Error refreshing CSRF token:', error);
    }
  };

  useEffect(() => {
    refreshCsrfToken();
    
    return () => {
      if (interceptorRef.current !== null) {
        axios.interceptors.request.eject(interceptorRef.current);
      }
    };
  }, []);

  // Check if CRSF token is "cleaned" after login or that it correctly expires. 
  return (
    <CsrfTokenContext.Provider value={{ csrfToken, refreshCsrfToken }}>
      {children}
    </CsrfTokenContext.Provider>
  );
};
