import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setUserAuthInfo } from '../../features/user/userSlice';
import { useLocation } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { useCsrfToken } from '../../utils/CsrfTokenContext';
import './RegistrationComplete.css';

export default function RegistrationComplete() {
    const { csrfToken, refreshCsrfToken } = useCsrfToken();
    const [digit1, setDigit1] = useState('');
    const [digit2, setDigit2] = useState('');
    const [digit3, setDigit3] = useState('');
    const [digit4, setDigit4] = useState('');
    const [isInvalid, setIsInvalid] = useState(false);
    const [resendCooldown, setResendCooldown] = useState(0);
    const [attemptsLeft, setAttemptsLeft] = useState(5);
    const [maxAttemptsReached, setMaxAttemptsReached] = useState(false);
    const digit1Ref = useRef(null);
    const digit2Ref = useRef(null);
    const digit3Ref = useRef(null);
    const digit4Ref = useRef(null);
    const toastRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { registrationUrlKey } = useParams();

    useEffect(() => {
        let timer;
        if (resendCooldown > 0) {
            timer = setTimeout(() => setResendCooldown(resendCooldown - 1), 1000);
        }
        return () => clearTimeout(timer);
    }, [resendCooldown]);

    const handleInputChange = (e, setter, nextRef) => {
        const value = e.target.value;
        if (value === '' || (/^[0-9]$/).test(value)) {
            setter(value);
            setIsInvalid(false);
            if (nextRef && value !== '') {
                nextRef.current.focus();
            }
        } else {
            setIsInvalid(true);
            digit1Ref.current.focus();
        }
    }

    useEffect(() => {
        if (digit1 !== '' && digit2 !== '' && digit3 !== '' && digit4 !== '') {
            const pass_key = `${digit1}${digit2}${digit3}${digit4}`;
  
            axios.get(`${process.env.REACT_APP_API_URL}/django_codedd/validate_pass_key/`, {
                params: {
                    pass_key,
                    registration_url_key: registrationUrlKey
                }
            })
            .then(async response => {
                if (response.data.status === 'success') {
                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem('accountUuid', response.data.account_uuid);
                    localStorage.setItem('accountName', response.data.account_name);
                    
                    try {
                        await refreshCsrfToken();
                    } catch (csrfError) {
                        console.error('CSRF refresh error:', csrfError);
                        throw csrfError;
                    }

                    axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
                    
                    dispatch(setUserAuthInfo({
                        token: response.data.token,
                        accountUuid: response.data.account_uuid,
                        isLoggedIn: true,
                        accountName: response.data.account_name,
                        isAdmin: response.data.is_admin || false
                    }));

                    const redirectTo = location.state?.from?.pathname || '/dashboard';
                    navigate(redirectTo, { replace: true });
                } else {
                    setIsInvalid(true);
                    setAttemptsLeft(response.data.attempts_left);
                    setDigit1('');
                    setDigit2('');
                    setDigit3('');
                    setDigit4('');
                    digit1Ref.current.focus();
                    toastRef.current.show({severity: 'error', summary: 'Invalid Passcode', detail: `Incorrect passcode. ${response.data.attempts_left} attempts left.`});
                }
            })
            .catch(error => {
                console.error('Error validating pass key:', error);
                setIsInvalid(true);
                setDigit1('');
                setDigit2('');
                setDigit3('');
                setDigit4('');
                digit1Ref.current.focus();
                if (error.response) {
                    if (error.response.status === 403 && error.response.data.max_attempts_reached) {
                        setMaxAttemptsReached(true);
                    } else {
                        toastRef.current.show({severity: 'error', summary: 'Error', detail: error.response.data.message || 'An error occurred. Please try again.'});
                    }
                } else {
                    toastRef.current.show({severity: 'error', summary: 'Error', detail: 'An error occurred. Please try again.'});
                }
            });
        }
    }, [digit1, digit2, digit3, digit4, registrationUrlKey, dispatch, location.state, navigate, refreshCsrfToken]);

    const handleResendPasskey = (e) => {
        e.preventDefault();
        if (resendCooldown > 0) {
            toastRef.current.show({
                severity: 'warn',
                summary: 'Cooldown Active',
                detail: `Please wait ${resendCooldown} seconds before requesting a new passcode.`,
                life: 3000
            });
            return;
        }
        axios.get(`${process.env.REACT_APP_API_URL}/django_codedd/resend_pass_key/`, {
            params: { registration_url_key: registrationUrlKey }
        })
        .then(response => {
            if (response.data.success) {
                toastRef.current.show({
                    severity: 'success',
                    summary: 'Passcode Sent',
                    detail: 'A new passcode has been sent to your email.',
                    life: 5000
                });
                setResendCooldown(300); // Set a 5-minute cooldown
            } else if (response.data.redirect) {
                toastRef.current.show({
                    severity: 'info',
                    summary: 'Registration Complete',
                    detail: 'Your registration is already complete. Redirecting to login...',
                    life: 5000
                });
                setTimeout(() => navigate(response.data.redirect), 5000);
            } else {
                toastRef.current.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: response.data.message || 'An error occurred. Please try again.',
                    life: 5000
                });
            }
        })
        .catch(error => {
            console.error('Error resending passcode:', error);
            if (error.response && error.response.status === 429) {
                toastRef.current.show({
                    severity: 'warn',
                    summary: 'Too Many Attempts',
                    detail: error.response.data.message || 'Please wait before requesting a new passcode.',
                    life: 5000
                });
            } else {
                toastRef.current.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'An error occurred while resending the passcode. Please try again later.',
                    life: 5000
                });
            }
        });
    };
  
    return (
        <div className="start-container">
            <Toast ref={toastRef} />
            <div className="content">
                <div className="card">
                    <h1 className="start-heading">Welcome to CodeDD</h1>
                    <h4>An email has been sent containing your pass key</h4>
                    <h2 className="start-subheading">Enter your 4-digit pass key to complete your registration</h2>
                    {maxAttemptsReached ? (
                        <div className="error-message">
                            Maximum number of attempts reached. Please contact support at support@codedd.ai.
                        </div>
                    ) : (
                        <>
                            <div className="input-container">
                                {[digit1, digit2, digit3, digit4].map((value, index) => (
                                    <input
                                        key={index}
                                        ref={[digit1Ref, digit2Ref, digit3Ref, digit4Ref][index]}
                                        type="text"
                                        value={value}
                                        onChange={(e) => handleInputChange(e, [setDigit1, setDigit2, setDigit3, setDigit4][index], [digit2Ref, digit3Ref, digit4Ref, null][index])}
                                        className={`single-digit-input ${isInvalid ? "input-invalid" : ""}`}
                                        maxLength={1}
                                        pattern="[0-9]*"
                                        inputMode="numeric"
                                        disabled={attemptsLeft <= 0}
                                    />
                                ))}
                            </div>
                            {isInvalid && <div className="error-message">Invalid pass key. {attemptsLeft} attempts left.</div>}
                            <div className="resend-link">
                                <a href="#" onClick={handleResendPasskey} className={resendCooldown > 0 ? "disabled" : ""}>
                                    {resendCooldown > 0 ? `Resend pass key (${resendCooldown}s)` : "Resend pass key"}
                                </a>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}