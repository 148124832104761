import React, { useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { DataView } from 'primereact/dataview';
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { selectAuth } from '../features/user/userSlice';
import { useCsrfToken } from '../utils/CsrfTokenContext';
import { useNavigate } from 'react-router-dom';

import './ExportData.css';

const ExportAuditResults = ({ closeModal, auditUuid }) => {
    const [isLoading, setIsLoading] = useState(false);
    const toast = useRef(null);
    const { csrfToken } = useCsrfToken();
    const { accountUuid } = useSelector(selectAuth);
    const navigate = useNavigate();

    // Export options data
    const exportOptions = [
        {
            id: 1,
            title: 'Files',
            description: 'Download a CSV with a complete review of all files',
            icon: 'pi pi-file',
            type: 'files'
        }
    ];

    /**
     * Shows an error toast with optional action button
     * @param {string} message - The error message to display
     * @param {boolean} requiresLogin - Whether the error requires user to login
     */
    const showErrorToast = (message, requiresLogin = false) => {
        toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: message,
            life: 6000,
            closable: true,
            sticky: requiresLogin,
            content: requiresLogin ? (
                <div className="flex flex-column">
                    <div className="text-red-500 mb-3">
                        <i className="pi pi-exclamation-circle mr-2" />
                        {message}
                    </div>
                    <Button
                        label="Login"
                        className="p-button-sm"
                        onClick={() => {
                            navigate('/login', { 
                                state: { 
                                    returnUrl: window.location.pathname,
                                    message: 'Please log in to download audit results.' 
                                } 
                            });
                        }}
                    />
                </div>
            ) : undefined
        });
    };

    /**
     * Validates the current session state
     * @returns {boolean} Whether the session is valid
     */
    const validateSession = () => {
        if (!csrfToken) {
            showErrorToast('Your session has expired. Please log in again to download the file.', true);
            return false;
        }

        if (!accountUuid) {
            showErrorToast('Account verification failed. Please log in again.', true);
            return false;
        }

        if (!auditUuid) {
            showErrorToast('Invalid audit reference. Please try accessing this page again.');
            return false;
        }

        return true;
    };

    /**
     * Handles file download by creating a temporary link and triggering download
     * @param {Blob} blob - The file blob to download
     * @param {string} filename - The name for the downloaded file
     */
    const downloadFile = (blob, filename) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        
        try {
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Download error:', error);
            showErrorToast('Failed to initiate download. Please try again.');
        } finally {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }
    };

    /**
     * Maps HTTP error codes to user-friendly messages
     * @param {number} status - HTTP status code
     * @param {string} defaultMessage - Default message if status isn't mapped
     * @returns {Object} Error message and whether it requires login
     */
    const getErrorDetails = (status, defaultMessage) => {
        const errorMap = {
            400: {
                message: 'The download request was invalid. Please try again.',
                requiresLogin: false
            },
            401: {
                message: 'Your session has expired. Please log in again to download the file.',
                requiresLogin: true
            },
            403: {
                message: 'You don\'t have permission to download this file. Please log in with an authorized account.',
                requiresLogin: true
            },
            404: {
                message: 'The requested file could not be found. It may have been deleted or moved.',
                requiresLogin: false
            },
            429: {
                message: 'Too many download attempts. Please wait a few minutes and try again.',
                requiresLogin: false
            },
            500: {
                message: 'The server encountered an error while preparing your download. Please try again later.',
                requiresLogin: false
            },
            502: {
                message: 'The download service is temporarily unavailable. Please try again later.',
                requiresLogin: false
            },
            503: {
                message: 'The system is under maintenance. Please try again in a few minutes.',
                requiresLogin: false
            }
        };

        return errorMap[status] || {
            message: defaultMessage,
            requiresLogin: false
        };
    };

    /**
     * Initiates the file download process with proper error handling
     * @param {string} type - The type of export being requested
     */
    const handleDownload = async (type) => {
        if (!validateSession()) {
            return;
        }
    
        setIsLoading(true);
    
        try {
            const response = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_API_URL}/django_codedd/download_file_csv/${auditUuid}/`,
                headers: {
                    'X-CSRFToken': csrfToken,
                    'Content-Type': 'application/json',
                    'Accept': '*/*',  // Accept any content type
                },
                withCredentials: true,
                responseType: 'blob'  // Important for file downloads
            });
    
            // Get filename from content disposition header
            const contentDisposition = response.headers['content-disposition'];
            const filename = contentDisposition
                ? contentDisposition.split('filename=')[1]?.replace(/['"]/g, '')
                : `audit-files-${auditUuid}-${new Date().toISOString().split('T')[0]}.csv`;
    
            // Create blob and download
            const blob = new Blob([response.data], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            
            try {
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            } catch (error) {
                console.error('Download error:', error);
                showErrorToast('Failed to initiate download. Please try again.');
            } finally {
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            }
    
            toast.current.show({
                severity: 'success',
                summary: 'Success',
                detail: 'File downloaded successfully',
                life: 3000
            });
        } catch (error) {
            console.error('Download error:', error);
            
            // Handle specific error cases
            if (error.response) {
                const { status } = error.response;
                const { message, requiresLogin } = getErrorDetails(
                    status,
                    'An unexpected error occurred while downloading the file'
                );
                showErrorToast(message, requiresLogin);
            } else if (error.code === 'ECONNABORTED') {
                showErrorToast('The download request timed out. Please check your connection and try again.');
            } else if (!navigator.onLine) {
                showErrorToast('No internet connection. Please check your connection and try again.');
            } else {
                showErrorToast('An unexpected error occurred. Please try again or contact support if the issue persists.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const itemTemplate = (item, index) => {
        return (
            <div className="col-12" key={item.id}>
                <div className={classNames('flex', 
                    { 'border-top-1 surface-border': index !== 0 })}>
                    
                    {/* Excel Icon Image */}
                    <div className="excel-icon-container">
                        <img src="/images/app/excel.webp" alt="Excel file" />
                    </div>
    
                    {/* Content Section */}
                    <div className="content-section">
                        <div className="text-2xl">{item.title}</div>
                        <div className="text-500">{item.description}</div>
                    </div>
    
                    {/* Button Container */}
                    <div className="button-container">
                        <Button 
                            label="Download CSV"
                            icon={isLoading ? null : "pi pi-download"}
                            onClick={() => handleDownload(item.type)}
                            disabled={isLoading}
                            style={{ minWidth: '200px', color: 'white' }}
                        >
                            {isLoading && <ProgressSpinner style={{ width: '20px', height: '20px' }} strokeWidth="4" />}
                        </Button>
                    </div>
                </div>
            </div>
        );
    };

    const listTemplate = (items) => {
        if (!items || items.length === 0) return null;
        const list = items.map((item, index) => itemTemplate(item, index));
        return <div className="grid grid-nogutter">{list}</div>;
    };

    return (
        <div className="export-audit-results">
            <Toast ref={toast} position="top-right" />
            
            <div className="downloads-data-view w-full">
                <DataView 
                    value={exportOptions} 
                    listTemplate={listTemplate}
                    className="border-1 surface-border border-round"
                />
            </div>
        </div>
    );
};

export default ExportAuditResults;