import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ProgressSpinner } from 'primereact/progressspinner';
import axios from 'axios';
import { utcToZonedTime } from 'date-fns-tz';
import { differenceInMinutes, differenceInHours, differenceInDays, 
         differenceInWeeks, differenceInMonths, differenceInYears } from 'date-fns';

// Utility Functions
const getElapsedTime = (utcTimeString, userTimeZone) => {
    const utcDate = new Date(utcTimeString);
    const userTime = utcToZonedTime(utcDate, userTimeZone);
    const currentTime = new Date();
    
    const diffYears = differenceInYears(currentTime, userTime);
    if (diffYears > 0) return `${diffYears} year${diffYears > 1 ? 's' : ''} ago`;
    
    const diffMonths = differenceInMonths(currentTime, userTime);
    if (diffMonths > 0) return `${diffMonths} month${diffMonths > 1 ? 's' : ''} ago`;
    
    const diffWeeks = differenceInWeeks(currentTime, userTime);
    if (diffWeeks > 0) return `${diffWeeks} week${diffWeeks > 1 ? 's' : ''} ago`;
    
    const diffDays = differenceInDays(currentTime, userTime);
    if (diffDays > 0) return `${diffDays} day${diffDays > 1 ? 's' : ''} ago`;
    
    const diffHours = differenceInHours(currentTime, userTime);
    if (diffHours > 0) return `${diffHours} hour${diffHours > 1 ? 's' : ''} ago`;
    
    const diffMinutes = differenceInMinutes(currentTime, userTime);
    if (diffMinutes > 0) return `${diffMinutes} minute${diffMinutes > 1 ? 's' : ''} ago`;
    
    return 'Just now';
};

const getRemainingTimeParts = (utcTimeString, userTimeZone) => {
    const utcDate = new Date(utcTimeString);
    const endTime = new Date(utcDate.getTime() + 14 * 24 * 60 * 60 * 1000);
    const userEndTime = utcToZonedTime(endTime, userTimeZone);
    
    const currentTime = new Date();
    const diff = userEndTime - currentTime;

    if (diff <= 0) {
        return { timeLeft: "Session expired", suffix: "" };
    }

    const diffDays = differenceInDays(userEndTime, currentTime);
    const diffHours = differenceInHours(userEndTime, currentTime) % 24;

    let timeLeft = diffDays > 0 
        ? `${diffDays} day${diffDays > 1 ? 's' : ''}${diffHours > 0 ? ` & ${diffHours} hour${diffHours > 1 ? 's' : ''}` : ''}`
        : `${diffHours} hour${diffHours > 1 ? 's' : ''}`;

    return { timeLeft, suffix: "left before audit session expires" };
};

const GroupAuditCard = ({ groupAudit, userTimeZone, accountUuid }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isNavigating, setIsNavigating] = useState(false);

    // Status constants
    const isTargetStatus = ["Opening", "Group audit ready"].includes(groupAudit.audit_status);
    const { timeLeft, suffix } = getRemainingTimeParts(groupAudit.ai_synthesis, userTimeZone);
    
    // Icon and status classes
    const iconClass = `pi ${
        groupAudit.audit_status === "Opening" ? 'pi-power-off' : 
        groupAudit.audit_status === "Group audit ready" ? 'pi-check-circle' : 
        'pi-circle'
    }`;
    
    const statusClass = `audit-status ${
        groupAudit.audit_status === "Opening" ? 'highlighted' : 
        groupAudit.audit_status === "Group audit ready" ? 'ready' : 
        ''
    }`;

    const nameClass = `repo-name ${isTargetStatus ? 'highlighted' : ''}`;
    const fileCountClass = `file-count ${isTargetStatus ? 'highlighted' : ''}`;

    const handleClick = async (e) => {
        e.preventDefault();
        if (isNavigating) return;

        try {
            setIsNavigating(true);
            
            // First check access and update Redux
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/django_codedd/is_uuid_path_public/`,
                {
                    params: {
                        audit_uuid: groupAudit.audit_uuid,
                        account_uuid: accountUuid
                    }
                }
            );

            // Update Redux state
            dispatch({
                type: 'SET_AUDIT_STATUS',
                payload: {
                    uuid: groupAudit.audit_uuid,
                    isPublic: response.data.publicity === 'public',
                    auditStatus: response.data.auditStatus,
                    hasAccess: response.data.hasAccess,
                    isManager: response.data.isManager
                }
            });

            // If we have access, navigate
            if (response.data.hasAccess) {
                navigate(`/${groupAudit.audit_uuid}/audit-invitation`);
            } else {
                console.error('Access denied to group audit');
                // You might want to show a toast or error message here
            }
        } catch (error) {
            console.error('Error checking group audit access:', error);
            // You might want to show a toast or error message here
        } finally {
            setIsNavigating(false);
        }
    };

    return (
        <div onClick={handleClick} className="audit-item-link" style={{ cursor: isNavigating ? 'wait' : 'pointer' }}>
            <div className="audit-item">
                <i 
                    className={`${iconClass} repo-logo`} 
                    style={{
                        fontSize: '30px', 
                        marginLeft: '3px'
                    }}
                />
                <div className="audit-details">
                    <span className={nameClass}>
                        {groupAudit.audit_name}
                    </span>
                    <span className="repo-info">
                        {groupAudit.number_of_related_audits} repositories in this group
                    </span>
                </div>
                <div className="audit-info">
                    <span className="ai-synthesis">
                        {getElapsedTime(groupAudit.ai_synthesis, userTimeZone)}
                    </span>
                    <span className={fileCountClass}>
                        {groupAudit.audit_status === "Group audit ready" 
                            ? "Audit ready" 
                            : (isTargetStatus 
                                ? "Audit list not complete" 
                                : `${groupAudit.number_files} files and ${groupAudit.lines_of_code} lines of code audited`)}
                    </span>
                    <span className={statusClass}>
                        {isTargetStatus ? (
                            <>
                                <strong style={{fontSize: "16px"}}>{timeLeft}</strong> {suffix}
                            </>
                        ) : groupAudit.audit_status}
                    </span>
                </div>
                {isNavigating && <ProgressSpinner style={{width: '20px', height: '20px'}} />}
            </div>
        </div>
    );
};

export default GroupAuditCard;