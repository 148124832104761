import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DataScroller } from 'primereact/datascroller';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { ProgressSpinner } from 'primereact/progressspinner';
import { selectAuth } from '../features/user/userSlice';
import EnterRepoCredentials from '../components/EnterRepoCredentials';
import InviteToAudit from '../components/InviteToAudit';
import { useCsrfToken } from '../utils/CsrfTokenContext';
import GroupAuditCard from './components/GroupAuditCard';
import RepoCard from './components/RepoCard';
import './UserDashboard.css';
              

function UserDashboard() {
    const { csrfToken } = useCsrfToken();
    const [gitUrl, setGitUrl] = useState('');
    const [audits, setAudits] = useState([]);
    const [groupAudits, setGroupAudits] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const limit = 10; // Number of audits to list per page
    const [offset, setOffset] = useState(0);
    const [hasLoadedMore, setHasLoadedMore] = useState(false);
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const [isLoading, setIsLoading] = useState(false);
    const [isCheckingUrl, setIsCheckingUrl] = useState(false);
    const [isValidUrl, setIsValidUrl] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorType, setErrorType] = useState('');
    const [showCredentialsModal, setShowCredentialsModal] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [shouldConnectWebSocket, setShouldConnectWebSocket] = useState(false);
    const dispatch = useDispatch();

    const { accountUuid, token } = useSelector(selectAuth);
    
    const navigate = useNavigate();

    const [auditUuid, setAuditUuid] = useState(''); 
    
    const [auditStarted, setAuditStarted] = useState(false);

    const fetchAuditDetails = async (auditUuid) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/django_codedd/audit_details/${auditUuid}/`);
            return response.data.file_count;
        } catch (error) {
            console.error('Error fetching audit details:', error);
            return 0;
        }
    };

    const fetchAudits = useCallback(async (loadMore = false) => {
        const newOffset = loadMore ? offset + limit : 0;
        setIsLoading(true);
        try {
            if (!accountUuid) {
                console.error('No account UUID found, redirecting to login.');
                navigate('/');
                return;
            }
            
            const headers = {
                'Authorization': `Bearer ${token}`,
            };
            
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/django_codedd/user_audits/`, {
                params: {
                    offset: newOffset,
                    limit: limit,
                    account_uuid: accountUuid
                },
                headers
            });
            const fetchedAudits = response.data.audits_data || [];
            const fetchedGroupAudits = response.data.group_audits_data || [];

            const auditsWithDetails = await Promise.all(fetchedAudits.map(async audit => {
                const file_count = await fetchAuditDetails(audit.audit_uuid);
                return { ...audit, file_count };
            }));

            setAudits(prev => loadMore ? [...prev, ...auditsWithDetails] : auditsWithDetails);
            setGroupAudits(prev => loadMore ? [...prev, ...fetchedGroupAudits] : fetchedGroupAudits);
            setTotalItems(response.data.total_audits);
            setOffset(newOffset);

            if (loadMore) {
                setHasLoadedMore(true);
            }

        } catch (error) {
            if (error.response && error.response.status === 401) {
                navigate('/login');
                return;
            }
            console.error('Error fetching audits:', error);
        }
        setIsLoading(false);
    }, [accountUuid, navigate, offset, token]);

    useEffect(() => {
        fetchAudits();
    }, [fetchAudits]);

    useEffect(() => {
        if (shouldConnectWebSocket) {
            const wsUrl = `${process.env.REACT_APP_WS_URL}/ws/django_codedd/audit/${accountUuid}/`;
            const ws = new WebSocket(wsUrl);
            
            ws.onopen = () => {
                console.log('WebSocket connected:', wsUrl);
            };
    
            ws.onmessage = (event) => {
                try {
                    const data = JSON.parse(event.data);
                    console.log('Data from WebSocket:', data);
                    if (data.status === 'File information imported') {
                        fetchAudits();
                    }
                } catch (error) {
                    console.error('Error parsing WebSocket message:', error);
                }
            };
    
            ws.onerror = (error) => {
                console.error('WebSocket error:', error);
            };
    
            ws.onclose = (event) => {
                console.log('WebSocket closed:', event.code, event.reason);
            };
    
            return () => {
                if (ws.readyState === WebSocket.OPEN) {
                    ws.close();
                }
            };
        }
    }, [shouldConnectWebSocket, accountUuid, fetchAudits]);

    const handleSubmit = async () => {
        setIsLoading(true);
        setErrorMessage('');
        setIsCheckingUrl(true);
        setAuditStarted(true);
        setErrorType('');
    
        try {
            // Step 1: Validate URL
            const validationResponse = await axios.post(
                `${process.env.REACT_APP_API_URL}/django_codedd/validate_git_url`,
                { git_repo_url: gitUrl, account_uuid: accountUuid },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': csrfToken,
                    },
                    withCredentials: true
                }
            );
            
            setIsCheckingUrl(false);
    
            if (!validationResponse.data.isValid) {
                handleValidationError(validationResponse.data);
                if (validationResponse.data.message === 'SSH access denied.') {
                    setShowCredentialsModal(true);
                }
                return;
            }
    
            // Step 2: Create audit
            setShouldConnectWebSocket(true);
            const auditScopeResponse = await axios.post(
                `${process.env.REACT_APP_API_URL}/django_codedd/audit_scope_selector/`,
                { git_repo_url: gitUrl, account_uuid: accountUuid },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': csrfToken,
                    },
                    withCredentials: true
                }
            );
    
            if (!auditScopeResponse.data.audit_uuid) {
                throw new Error('No audit UUID received');
            }
    
            // Step 3: Set audit UUID and navigate directly
            const auditUuid = auditScopeResponse.data.audit_uuid;
            setAuditUuid(auditUuid);
            
            // Since we just created the audit, we know we have access
            // Dispatch the audit status to Redux before navigation
            dispatch({
                type: 'SET_AUDIT_STATUS',
                payload: {
                    uuid: auditUuid,
                    hasAccess: true,
                    isPublic: false,
                    auditStatus: 'created'
                }
            });
    
            // Navigate directly without checking access again
            navigate(`/${auditUuid}/audit-scope-selection`);
            setShouldConnectWebSocket(false);
    
        } catch (error) {
            setShouldConnectWebSocket(false);
            setIsCheckingUrl(false);
            
            if (error.response?.data?.message === "SSH access denied.") {
                setShowCredentialsModal(true);
            } else {
                setErrorMessage(error.response?.data?.message || 'An error occurred while processing the URL.');
                setErrorType(error.response?.data?.errorType || 'unknown');
                setIsValidUrl(false);
            }
        } finally {
            setIsLoading(false);
        }
    };

    // Helper function to handle validation errors
    const handleValidationError = (data) => {
        const errorMessages = {
            invalidUrl: "This is not a valid URL.",
            invalidGitUrl: "This is not a valid git root URL to clone.",
            accessError: "SSH access denied.",
            default: "An unknown error occurred."
        };

        setErrorMessage(errorMessages[data.errorType] || errorMessages.default);
        setErrorType(data.errorType || 'unknown');
        setIsValidUrl(false);
    };

    const renderAuditItem = (audit) => {
        if (audit.isGroupAudit) {
            return <GroupAuditCard 
                groupAudit={audit} 
                userTimeZone={userTimeZone} 
                accountUuid={accountUuid} 
            />;
        }
        return <RepoCard 
            audit={audit} 
            userTimeZone={userTimeZone} 
            accountUuid={accountUuid} 
        />;
    };

    useEffect(() => {
        if (auditStarted && !showCredentialsModal) {
            const timer = setTimeout(() => {
                setShowMessage(true);
            }, 10000);  // Delay of 10 seconds

            return () => clearTimeout(timer);  // Cleanup the timer
        }
    }, [auditStarted, showCredentialsModal]);


    const getSortedAudits = useCallback(() => {
        const combinedAudits = [...audits, ...groupAudits.map(audit => ({...audit, isGroupAudit: true}))];
        combinedAudits.sort((a, b) => new Date(b.ai_synthesis) - new Date(a.ai_synthesis));
        return combinedAudits;
    }, [audits, groupAudits]);

    const canLoadMore = totalItems > audits.length + groupAudits.length;

        const handleLoadMore = () => {
            if (canLoadMore) {
                fetchAudits(true);
            }
        };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    }

    const isButtonDisabled = !gitUrl.trim() || isLoading || isCheckingUrl;

    return (
        <div className="user-dashboard-container">
            <InviteToAudit accountUuid={accountUuid}/>
            <EnterRepoCredentials
                isVisible={showCredentialsModal}
                onHide={() => setShowCredentialsModal(false)}
                gitUrl={gitUrl}
                onAuthenticate={handleSubmit}
                accountUuid={accountUuid}
            />

            <div className="divider-container">
                <hr className="line" />
                <span className="divider-text">or</span>
                <hr className="line" />
            </div>

            <div className="direct-audit-container">

                <div className="audit-repo-title">Audit a git repository directly</div>
                <div className="audit-repo-inputcontainer-dashboard">
                    <span className="audit-repo-inputfield-dashboard">
                    <InputText
                        id="gitUrl"
                        value={gitUrl}
                        onChange={(e) => {
                            setGitUrl(e.target.value);
                            setIsValidUrl(true);
                        }}
                        onKeyDown={handleKeyDown}
                        placeholder="e.g. https://github.com/tensorflow/tensorflow"
                        className={`${isValidUrl ? '' : 'p-invalid'}`}
                    />
                    </span>
                    <Button
                        className='audit-repo-button-dashboard'
                        label="Analyze" 
                        onClick={handleSubmit} 
                        disabled={isButtonDisabled} 
                        icon={isLoading || isCheckingUrl ? 'pi pi-spin pi-spinner' : 'pi pi-bolt'}
                        iconPos="left"  // Explicitly set icon position
                    />
                </div>

            </div>

            <div className="error-message-container">
                {errorType && <Message severity="error" text={errorMessage} />}
            </div>

            {auditStarted && !showCredentialsModal && showMessage && (
                <div className='audit-status-container'>
                    <h4>
                        Your audit started. This might take a few minutes depending on the size of the repo...
                    </h4>
                </div>
            )}

            {(audits.length > 0 || groupAudits.length > 0) && (
                <div className="repository-list-section">
                    <DataScroller 
                        value={getSortedAudits()}
                        itemTemplate={renderAuditItem}
                        rows={limit}
                        loader={isLoading}
                        footer={canLoadMore || (hasLoadedMore && !canLoadMore) ? (
                            <Button 
                                label={canLoadMore ? "Load More" : "No More Items"}
                                onClick={handleLoadMore}
                                disabled={isLoading || !canLoadMore}
                            />
                        ) : null}
                    />
                </div>
            )}
            
            {isLoading && (
                <ProgressSpinner 
                    style={{marginTop: '3rem', width: '50px', height: '50px'}}
                />
            )}
        </div>
    );
}

export default UserDashboard;