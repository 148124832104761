// audit_invitation_components/invitee_view.js
import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import './InviteeView.css';

// Implement onConfirmClick function together with rendering the button to confirm audit scope selection. 
//Pass setAuditReady as a prop to enable the audit start section.

const InviteeView = ({
    auditName,
    gitUrl,
    isCheckingUrl,
    isValidUrl,
    isLoading,
    errorMessage,
    handleInputChange,
    handleSubmit,
    userName,
    ShowCredentialsModal,
    auditFinished,
    auditStarted
}) => {
    const isAddButtonDisabled = !gitUrl || isLoading || isCheckingUrl || !isValidUrl || auditStarted;
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        if (isLoading && !ShowCredentialsModal) {
            const timer = setTimeout(() => {
                setShowMessage(true);
            }, 10000);  // Delay of 10 seconds

            return () => clearTimeout(timer);  // Cleanup the timer
        }
    }, [isLoading, ShowCredentialsModal]);

    return (
        <div>
            <div className='audit-invitation-header' style={{ paddingLeft: "5%", paddingTop: "30px" }}>
                <h1 style={{ fontSize: "24px" }}>
                    Invitation to audit: <span style={{ color: "#C049DE", fontSize: "24px" }}>{auditName}</span>
                </h1>
                <p className="sub-text" style={{ color: "#8D8D8D", marginTop: "10px" }}>
                    You have been invited by <strong>{userName}</strong> to submit your code repositories for auditing.
                </p>
                <p className="sub-text" style={{ color: "#8D8D8D", marginTop: "10px" }}>
                    {auditStarted 
                        ? "The audit has started. You can no longer add or modify repositories."
                        : "Add all relevant repositories, and if necessary, adapt the files selection you want to include in the due diligence."
                    }
                </p>
                <hr style={{ border: "1px solid #32AFC3", marginBottom: "45px", marginTop: "15px", maxWidth: '900px' }} />
            </div>
            {!auditFinished && !auditStarted && ( 
                <div className="repository-input-section" style={{ paddingLeft: "5%" }}>
                    <div className="header-container">Add a <strong>repository</strong> to be audited:</div>
                    <div className="input-section-container" style={{ justifyContent: "flex-start" }}>
                        <span className="p-input-icon-right">
                            <InputText
                                id="gitUrl"
                                value={gitUrl}
                                onChange={handleInputChange}
                                placeholder="e.g. https://github.com/tensorflow/tensorflow"
                                className={`${!isValidUrl ? 'p-invalid' : ''}`}
                                style={{ width: "450px", marginRight: "10px" }}
                                disabled={auditStarted}
                            />
                        </span>
                        <Button 
                            className='add-repo-button' 
                            label={ isCheckingUrl ? 'Adding' : 'Add'}
                            onClick={handleSubmit} 
                            disabled={isAddButtonDisabled}
                            icon={isLoading || isCheckingUrl ? 'pi pi-spin pi-spinner' : 'pi pi-plus'}
                            iconPos="left"
                        />
                        {errorMessage && (
                            <Message severity="error" text={errorMessage} style={{ marginLeft: "5px" }} />
                        )}
                    </div>

                    {isLoading && !ShowCredentialsModal && showMessage && (
                        <div className='audit-status-container'>
                            <div className="audit-status-message" style={{color: '#8D8D8D', fontSize: '16px', marginTop: '5px'}}>Your audit started. This might take a few minutes depending on the size of the repo...</div>
                        </div>
                    )}
                </div>
            )}
            
        </div>
    );
}

export default InviteeView;