// App.js
import './axiosConfig';
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet } from 'react-router-dom';

import { PrimeReactProvider } from 'primereact/api';
import { useSelector } from 'react-redux';
import { selectAuth } from './features/user/userSlice';
import { AuditAccessProvider } from './utils/AuditAccessContext';
import { CsrfTokenProvider } from './utils/CsrfTokenContext';
import AxiosConfigInitializer from './utils/AxiosConfigInitializer';
import { PrivateRoute } from './RouteConfig';

// Import components
import Start from './Start';
import UserDashboard from './user_dashboard/UserDashboard';
import AuditInvitation from './audit/AuditInvitation';
import AuditSummary from './audit/AuditSummary';
import FlagsView from './audit/Flags';
import DependenciesView from './audit/Dependencies';
import FilesView from './audit/Files';
import GlobalSidebar from './components/GlobalSidebar';
import AuditScopeSelection from './user_dashboard/AuditScopeSelection';
import Login from './landingpage/login/Login';
import ForgotPassword from './landingpage/login/ForgotPassword';
import Register from './landingpage/registration/Registration';
import RegistrationComplete from './landingpage/registration/RegistrationComplete';
import Account from './user_dashboard/Account';
import Terms from './landingpage/legals/Terms';
import DataProcessingAddendum from './landingpage/legals/DataProcessingAddendum';
import Error from './landingpage/error/Error';
import EmptyPlaceholder from './landingpage/error/Empty';
import Navigation from './components/landingpage/Navigation';
import Footer from './components/landingpage/Footer';
import ResetPassword from './landingpage/login/ResetPassword';
import TryDemo from './landingpage/demo/TryDemo';

// Import admin components
import AdminPanel from './admin/AdminDashboard';

// Import styles
import './App.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import './index.css';

import axios from 'axios';

// Add request interceptor
axios.interceptors.request.use(
    config => {
        const token = localStorage.getItem('authToken');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// Add response interceptor to handle 401s
axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401) {
            // Clear local storage and redux store
            localStorage.removeItem('authToken');
            // Dispatch logout action if needed
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);

const PublicLayout = () => (
  <div className="public-layout">
    <Navigation />
    <main className="public-content">
      <Outlet />
    </main>
    <Footer />
  </div>
);

const PrivateLayout = () => {
  const [sidebarLocked, setSidebarLocked] = useState(false);
  const handleSidebarToggle = (isLocked) => setSidebarLocked(isLocked);

  return (
    <>
      <GlobalSidebar onLockToggle={handleSidebarToggle} />
      <Outlet />
    </>
  );
};

const App = () => {
  const { isLoggedIn } = useSelector(selectAuth);

  return (
    <CsrfTokenProvider>
      <PrimeReactProvider value={{ ripple: true }}>
        <Router>
          <AxiosConfigInitializer />
          <AuditAccessProvider>
          <Routes>
            {/* Public routes */}
            <Route element={<PublicLayout />}>
              <Route path="/" element={<Start />} />
              <Route 
                path="/login" 
                element={isLoggedIn ? <Navigate to="/dashboard" replace /> : <Login />} 
              />
              <Route path="/register" element={<Register />} />
              <Route path="/register/:email" element={<Register />} />
              <Route path="/complete-registration/:registrationUrlKey" element={<RegistrationComplete />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/pricing" element={<EmptyPlaceholder />} />
              <Route path="/error" element={<Error />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/data-processing-addendum" element={<DataProcessingAddendum />} />
              <Route path="/privacy" element={<EmptyPlaceholder />} />
              <Route path="/why-codedd" element={<EmptyPlaceholder />} />
              <Route path="/platform" element={<EmptyPlaceholder />} />
              <Route path="/password/reset" element={<ResetPassword />} />
              <Route path="/demo" element={<TryDemo />} />
            </Route>

            {/* Protected routes */}
            <Route element={<PrivateRoute />}>
            <Route element={<PrivateLayout />}>
              <Route path="/dashboard" element={<UserDashboard />} />
              <Route path="/account" element={<Account />} />
              <Route path="/:groupAuditUuid/audit-invitation" element={<AuditInvitation />} />
              <Route path="/:auditUuid/audit_summary" element={<AuditSummary />} />
              <Route path="/:auditUuid/flags" element={<FlagsView />} />
              <Route path="/:auditUuid/dependencies" element={<DependenciesView />} />
              <Route path="/:auditUuid/audit-scope-selection" element={<AuditScopeSelection />} />
              <Route path="/:auditUuid/files" element={<FilesView />} />
              <Route path="/codedd-admin" element={<AdminPanel />} />
            </Route>
            </Route>
            {/* Catch-all route for 404 */}
            <Route path="*" element={<Navigate to="/error" replace />} />
          </Routes>
          </AuditAccessProvider>
        </Router>
      </PrimeReactProvider>
    </CsrfTokenProvider>
  );
};

export default App;