import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import './DependenciesGraph.css';

const DependenciesGraph = ({ dependencies }) => {
  const graphRef = useRef();
  const minWidth = 600;
  const height = 600;
  const originalFileWidth = 120;
  const originalFileHeight = 25;
  const originalPackageRx = 40;
  const originalPackageRy = 20;

  useEffect(() => {
    if (!dependencies || !dependencies.nodes || !dependencies.edges) {
      console.error('Invalid or incomplete data provided for DependenciesGraph');
      return;
    }

    const handleResize = () => {
      const containerWidth = graphRef.current.parentElement.offsetWidth;
      const dynamicWidth = Math.max(containerWidth, minWidth);
      updateGraphWidth(dynamicWidth);
    };

    window.addEventListener('resize', handleResize);

    const width = Math.max(graphRef.current.parentElement.offsetWidth, minWidth);
    renderGraph(width, height, dependencies.nodes, dependencies.edges);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dependencies]);

  let linkedByIndex = {};
  dependencies.edges.forEach(d => {
    linkedByIndex[`${d.source.index},${d.target.index}`] = true;
  });


  function isConnected(a, b) {
    return linkedByIndex[`${a.index},${b.index}`] || linkedByIndex[`${b.index},${a.index}`] || a.index === b.index;
  }

  const removePackagePrefix = (packageName) => {
    const prefixPattern = /\$\![a-z]+\$!\_/i; // Regex to match the prefix pattern
    return packageName.replace(prefixPattern, ''); // Remove the prefix and return the name
  };

  // Function to handle dragging
  const drag = (simulation) => {
    function dragstarted(event) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      event.subject.fx = event.subject.x;
      event.subject.fy = event.subject.y;
    }

    function dragged(event) {
      event.subject.fx = event.x;
      event.subject.fy = event.y;
    }

    function dragended(event) {
      if (!event.active) simulation.alphaTarget(0);
      event.subject.fx = null;
      event.subject.fy = null;
    }

    return d3.drag()
      .on('start', dragstarted)
      .on('drag', dragged)
      .on('end', dragended);
  };

  // Function to render the graph
  const renderGraph = (width, height, nodes = [], edges = []) => {
    // Remove any existing SVG elements to reset the graph
    d3.select(graphRef.current).select("svg").remove();

    // Verify the graph dimensions are numbers and fallback if necessary
    width = Number.isFinite(width) ? width : 800;
    height = Number.isFinite(height) ? height : 600;

    // Calculate padding to keep nodes within bounds
    const padding = 40;

    // Create boundary force function
    const boundaryForce = () => {
      for (let node of nodes) {
        // Calculate node radius based on type
        const nodeRadius = node.type === 'file' ? originalFileHeight + 30 : originalPackageRy + 30;

        // Constrain x position
        node.x = Math.max(nodeRadius + padding, Math.min(width - nodeRadius - padding, node.x || width/2));
        // Constrain y position
        node.y = Math.max(nodeRadius + padding, Math.min(height - nodeRadius - padding, node.y || height/2));
      }
    };

    const svg = d3.select(graphRef.current)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .style('overflow', 'hidden'); // Ensure no overflow

    // Create a group for the graph elements
    const g = svg.append('g');

    // Set up zoom behavior with constraints
    const zoom = d3.zoom()
      .scaleExtent([0.1, 10])
      .translateExtent([[0, 0], [width, height]]) // Constrain panning
      .on('zoom', (event) => {
        const { x, y, k } = event.transform;
        if (Number.isFinite(x) && Number.isFinite(y) && Number.isFinite(k)) {
          g.attr('transform', `translate(${x},${y}) scale(${k})`);
        }
      });

    // Adjust force simulation with boundary forces
    const simulation = d3.forceSimulation(nodes)
      .force('link', d3.forceLink(edges).id(d => d.id).distance(150))
      .force('charge', d3.forceManyBody()
        .strength(-300)
        .distanceMin(100)
        .distanceMax(400))
      .force('center', d3.forceCenter(width / 2, height / 2))
      .force('x', d3.forceX(width / 2).strength(0.05))
      .force('y', d3.forceY(height / 2).strength(0.05))
      .force('collision', d3.forceCollide().radius(function (d) {
        return d.type === 'file' ? originalFileHeight + 30 : originalPackageRy + 30;
      }).strength(0.8))
      .alphaDecay(0.02)
      .velocityDecay(0.4);

    // Add boundary force to each tick
    simulation.on('tick', () => {
      boundaryForce();
      
      link
        .attr('x1', d => d.source.x)
        .attr('y1', d => d.source.y)
        .attr('x2', d => d.target.x)
        .attr('y2', d => d.target.y);

      nodeGroup.select('rect')
        .attr('x', d => d.x - 60)
        .attr('y', d => d.y - 12);

      nodeGroup.select('ellipse')
        .attr('cx', d => d.x)
        .attr('cy', d => d.y);

      nodeGroup.select('text')
        .attr('x', d => d.x)
        .attr('y', d => d.y);
    });

    // Add container styles
    const containerStyle = document.createElement('style');
    containerStyle.textContent = `
      .dependencies-graph {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100%;
      }
      .dependencies-graph svg {
        display: block;
        max-width: 100%;
        max-height: 100%;
      }
    `;
    document.head.appendChild(containerStyle);

    // Create tooltip div
    const tooltip = d3.select(graphRef.current)
      .append("div")
      .attr("class", "graph-tooltip")
      .style("opacity", 0)
      .style("position", "absolute")
      .style("background-color", "white")
      .style("border", "1px solid #ddd")
      .style("border-radius", "4px")
      .style("padding", "8px")
      .style("pointer-events", "none")
      .style("font-size", "12px")
      .style("box-shadow", "0 2px 4px rgba(0,0,0,0.1)");

    const link = g.append('g')
      .attr("class", "links")
      .selectAll('line')
      .data(edges)
      .enter().append('line')
      .attr('stroke-width', 1)
      .attr('stroke', '#999');

    const nodeGroup = g.selectAll('.node-group')
      .data(nodes)
      .enter().append('g')
      .attr('class', 'node-group')
      .call(drag(simulation));

    nodeGroup.filter(d => d.type === 'file').append('rect')
      .attr('width', originalFileWidth)
      .attr('height', originalFileHeight)
      .attr('rx', 6)
      .attr('ry', 6)
      .attr('class', 'node file');

    nodeGroup.filter(d => d.type === 'package').append('ellipse')
      .attr('rx', originalPackageRx)
      .attr('ry', originalPackageRy)
      .attr('class', 'node package');

    nodeGroup.append('text')
      .text(d => {
        let text = '';
        if (d.type === 'file' && d.file_path) {
          text = d.file_path.split('/').pop() || 'Unknown File';
        } else if (d.type === 'package' && d.name) {
          text = removePackagePrefix(d.name) || 'Unknown Package';
        } else {
          text = d.type === 'file' ? 'Unknown File' : 'Unknown Package';
        }
        const maxLength = d.type === 'file' ? 10 : 8;
        return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
      })
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .attr('class', 'graph-label');

    /*
      Mouseover and mouseout events - now commented out as it was not working as expected
    nodeGroup.on('mouseover', function (_, d) {
        let isTruncated = d.type === 'file' ? d.file_path.split('/').pop().length > 10 : d.name.length > 8;
        let expandedWidth = isTruncated ? (d.type === 'file' ? 160 : 80) : (d.type === 'file' ? 132 : 44);
        let expandedHeight = isTruncated ? (d.type === 'file' ? 100 : 40) : (d.type === 'file' ? 28 : 22);

        d3.select(this).select('rect, ellipse')
            .transition().duration(200)
            .attr('width', d.type === 'file' ? expandedWidth : null)
            .attr('height', d.type === 'file' ? expandedHeight : null)
            .attr('rx', d.type === 'package' ? expandedWidth / 2 : null)
            .attr('ry', d.type === 'package' ? expandedHeight / 2 : null);

        // Text expansion logic
        d3.select(this).select('text')
            .selectAll('tspan').remove(); // Clear existing tspans

        const fullText = d.type === 'file' ? d.file_path.split('/').pop() : d.name;
        const lines = wrapText(fullText, 15); // Assuming 15 characters per line

        lines.forEach((line, index) => {
            d3.select(this).select('text')
                .append('tspan')
                .text(line)
                .attr('x', d.x - expandedWidth / 2) // Position the tspan
                .attr('dy', index > 0 ? '1em' : 0) // Adjust vertical spacing for subsequent lines
                .attr('text-anchor', 'start');
        });
    })

    .on('mouseout', function (_, d) {
        d3.select(this).select('rect, ellipse')
            .transition().duration(200)
            .attr('width', d.type === 'file' ? originalFileWidth : null)
            .attr('height', d.type === 'file' ? originalFileHeight : null)
            .attr('rx', d.type === 'package' ? originalPackageRx : null)
            .attr('ry', d.type === 'package' ? originalPackageRy : null);

        // Reset text to original state
        d3.select(this).select('text')
            .selectAll('tspan').remove(); // Clear tspans

        let text = d.type === 'file' ? d.file_path.split('/').pop() : d.name;
        let maxLength = d.type === 'file' ? 10 : 8;
        text = text.length > maxLength ? text.substring(0, maxLength) + '...' : text;

        d3.select(this).select('text')
            .text(text)
            .attr('x', d.x)
            .attr('y', d.y)
            .attr('text-anchor', 'middle')
            .attr('alignment-baseline', 'middle');
    });
    */

    nodeGroup.on('mouseover', null).on('mouseout', null);
      
    // Click event
    nodeGroup.on('click', function(event, clickedNode) {
      // Prevent the click from propagating to the SVG
      event.stopPropagation();
      
      // Fix the position of the clicked node
      clickedNode.fx = clickedNode.x;
      clickedNode.fy = clickedNode.y;
      
      nodeGroup.style('opacity', function(d) {
          return isConnected(clickedNode, d) ? 1 : 0.3; // More contrast
      });
      
      link.style('opacity', function(l) {
          return (l.source === clickedNode || l.target === clickedNode) ? 1 : 0.2;
      });
      
      // Gently update the simulation
      simulation.alpha(0.3).restart();
    });

    // Reset opacity and unfix nodes on SVG click
    svg.on('click', function() {
      nodes.forEach(node => {
        node.fx = null;
        node.fy = null;
      });
      nodeGroup.style('opacity', 1);
      link.style('opacity', 1);
      simulation.alpha(0.3).restart();
    });

    // Fit the graph into view after the initial render
    simulation.on('end', () => {
      // Wait for the next event loop tick to ensure the DOM elements are rendered
      setTimeout(() => {
        const bounds = g.node().getBBox();
        if (!bounds) return; // Add this check
      
        const dx = bounds.width;
        const dy = bounds.height;
        const x = bounds.x + dx / 2;
        const y = bounds.y + dy / 2;
      
        const scale = 0.9 / Math.max(dx / width, dy / height);
        const translate = [width / 2 - scale * x, height / 2 - scale * y];
      
        svg.transition()
          .duration(750)
          .call(zoom.transform, d3.zoomIdentity.translate(translate[0], translate[1]).scale(scale));
      }, 0);
    });

    // Add hover events to nodeGroup
    nodeGroup
      .on('mouseover', function(event, d) {
        tooltip.transition()
          .duration(200)
          .style("opacity", 0.9);
        
        const tooltipText = d.type === 'file' 
          ? d.file_path.split('/').pop()
          : removePackagePrefix(d.name);
        
        tooltip.html(tooltipText)
          .style("left", (event.pageX + 10) + "px")
          .style("top", (event.pageY - 10) + "px");
      })
      .on('mousemove', function(event) {
        tooltip
          .style("left", (event.pageX + 10) + "px")
          .style("top", (event.pageY - 10) + "px");
      })
      .on('mouseout', function() {
        tooltip.transition()
          .duration(500)
          .style("opacity", 0);
      });

    // Add CSS styles for the tooltip
    const style = document.createElement('style');
    style.textContent = `
      .graph-tooltip {
        z-index: 1000;
        max-width: 300px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    `;
    document.head.appendChild(style);
  };

    
  
  const updateGraphWidth = (newWidth) => {
    d3.select(graphRef.current).select("svg").attr('width', newWidth);
    // Optionally, re-center or adjust the graph here if needed
  };
  
  return <div ref={graphRef} className="dependencies-graph"></div>;
};
  
  export default DependenciesGraph;