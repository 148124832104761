// store.js
import { configureStore } from '@reduxjs/toolkit';
import userReducer from './features/user/userSlice';
import auditReducer from './utils/Reducer';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';

// Persist configuration
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'audits'] // Also persist audits state
};

// Combining all reducers
const rootReducer = combineReducers({
  user: userReducer,
  audits: auditReducer
});

// Applying persist configuration
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configuring store
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE', 'persist/REGISTER'],
      },
    }),
});

export const persistor = persistStore(store);