import React, { useRef, useState, useEffect } from 'react';

const SliderWithScore = ({ scoreAttribute, name, isMainChapter, auditDetails }) => {
  const rawScoreValue = auditDetails ? auditDetails[scoreAttribute] : 0;
  const scoreValue = Math.round(rawScoreValue); // Round the score value to the nearest whole number
  const sliderContainerClass = isMainChapter ? 'score-slider-container-large' : 'score-slider-container-small';
  const indicatorSize = isMainChapter ? 60 : 40; // Size of the indicator based on whether it's a main chapter

  const scoreBarRef = useRef(null); // Reference to the score bar DOM element
  const [scoreBarWidth, setScoreBarWidth] = useState(0); // State to keep track of the score bar width

  // Determine the color based on the score value
  let scoreColor = "#2CB392"; // Default green color
  if (scoreValue < 33) {
    scoreColor = "#E83636"; // Red color for low scores
  } else if (scoreValue < 66) {
    scoreColor = "#DE7539"; // Orange color for medium scores
  }

  // This function updates the score bar width
  const updateScoreBarWidth = () => {
    if (scoreBarRef.current) {
      setScoreBarWidth(scoreBarRef.current.offsetWidth); // Update the score bar width based on the current offsetWidth
    }
  };

  // This effect sets up the resize listener and updates the score bar width
  useEffect(() => {
    updateScoreBarWidth(); // Set initial width
    window.addEventListener('resize', updateScoreBarWidth); // Add resize event listener

    // Clean up function to remove the resize event listener
    return () => {
      window.removeEventListener('resize', updateScoreBarWidth);
    };
  }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount

  // Calculate the left position for the score indicator - 20px added for padding
  const leftPosition = (scoreValue / 100) * scoreBarWidth - (indicatorSize / 2);

  return (
    <div className={`${sliderContainerClass} score-slider-container`}>
      <div className="score-bar" ref={scoreBarRef}>
        <div className="score-fill" style={{ width: `${scoreValue}%`, backgroundColor: scoreColor }}></div>
      </div>
      <div className="score-indicator" style={{
        width: `${indicatorSize}px`,
        height: `${indicatorSize}px`,
        backgroundColor: scoreColor,
        left: `${leftPosition}px`
      }}>
        {scoreValue}
      </div>
    </div>
  );
};

export default SliderWithScore;

