import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';
import './FlagsGraph.css';

const FlagsGraph = ({ data }) => {
  const ref = useRef();

  useEffect(() => {
    if (!data || !ref.current) return;

    // Clear any existing SVG
    d3.select(ref.current).selectAll("*").remove();

    // Make width responsive to container
    const containerWidth = ref.current.parentElement.clientWidth;
    const width = Math.min(400, containerWidth);
    const height = width;
    const radius = width / 6;

    // Calculate responsive font size based on width
    const baseFontSize = width / 40; // This will scale the font with the container
    const fontSize = Math.max(8, Math.min(12, baseFontSize)); // Clamp between 8px and 12px

    const colors = {
      "No flags": "#8D8D8D",
      "Red": "#E83636",
      "Orange": "#DE7539"
    };

    const hierarchy = d3.hierarchy(data)
      .sum(d => d.value)
      .sort((a, b) => b.value - a.value);
      
      const root = d3.partition()
      .size([2 * Math.PI, hierarchy.height + 1])(hierarchy);
      
    root.each(d => d.current = d);

    const arc = d3.arc()
    .startAngle(d => d.x0)
    .endAngle(d => d.x1)
    .padAngle(d => Math.min((d.x1 - d.x0) / 2, 0.005))
    .padRadius(radius * 1.5)
    .innerRadius(d => d.y0 * radius)
    .outerRadius(d => Math.max(d.y0 * radius, d.y1 * radius - 1))

    const svg = d3.select(ref.current)
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", `-${width / 2},${-height / 2},${width},${height}`)
      .style("font", `${fontSize}px sans-serif`);

    const path = svg.append("g")
      .selectAll("path")
      .data(root.descendants().slice(1))
      .join("path")
      .attr("fill", d => {
        if (d.depth === 1) {
          return colors[d.data.name]; // Use the flag color directly
        } else if (d.depth === 2) {
          return colors[d.parent.data.name]; // Use parent flag color for domains
        }
      })
      .attr("fill-opacity", d => {
        if (d.depth === 1) {
          return 1.0;
        } else if (d.depth === 2) {
          const siblings = d.parent.children;
          const maxValue = d3.max(siblings, s => s.value);
          return d.value === maxValue ? 1.0 : 0.6;
        }
        return 0.6;
      })
      .attr("pointer-events", d => (arcVisible(d.current) && d.depth === 1) ? "auto" : "none")
      .attr("d", d => arc(d.current))
      .on("click", clicked);

    path.append("title")
      .text(d => `${d.ancestors().map(d => d.data.name).reverse().join("/")}\n${d.value}`);

    const label = svg.append("g")
      .attr("pointer-events", "none")
      .attr("text-anchor", "middle")
      .style("user-select", "none")
      .selectAll("text")
      .data(root.descendants().slice(1))
      .join("text")
      .attr("dy", "0.35em")
      .attr("fill-opacity", d => +labelVisible(d.current))
      .attr("transform", d => labelTransform(d.current))
      .text(d => d.data.name)
      .style("font-size", `${fontSize}px`);


    const parent = svg.append("circle")
      .datum(root)
      .attr("r", radius)
      .attr("fill", "none")
      .attr("pointer-events", "all")
      .on("click", clicked);

    function clicked(event, p) {
      parent.datum(p.parent || root);

      root.each(d => d.target = {
        x0: Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
        x1: Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
        y0: Math.max(0, d.y0 - p.depth),
        y1: Math.max(0, d.y1 - p.depth)
      });

      const t = svg.transition().duration(750);

      path.transition(t)
        .tween("data", d => {
          const i = d3.interpolate(d.current, d.target);
          return t => d.current = i(t);
        })
        .filter(function(d) {
          return +this.getAttribute("fill-opacity") || arcVisible(d.target);
        })
        .attr("fill-opacity", d => arcVisible(d.target) ? (d.children ? 0.6 : 0.4) : 0)
        .attr("pointer-events", d => arcVisible(d.target) ? "auto" : "none")
        .attrTween("d", d => () => arc(d.current));

      label.filter(function(d) {
        return +this.getAttribute("fill-opacity") || labelVisible(d.target);
      }).transition(t)
        .attr("fill-opacity", d => +labelVisible(d.target))
        .attrTween("transform", d => () => labelTransform(d.current));
    }

    function arcVisible(d) {
      return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
    }

    function labelVisible(d) {
      // Adjust the visibility threshold based on the width
      const threshold = width < 300 ? 0.06 : 0.03;
      return d.y1 <= 3 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > threshold;
    }

    function labelTransform(d) {
      const x = (d.x0 + d.x1) / 2 * 180 / Math.PI;
      const y = (d.y0 + d.y1) / 2 * radius;
      return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
    }

  }, [data]);

  return (
    <svg 
      ref={ref} 
      style={{
        width: '100%',
        height: 'auto',
        maxWidth: '400px'
      }}
      preserveAspectRatio="xMidYMid meet"
    ></svg>
  );
};

export default FlagsGraph;
